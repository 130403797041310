import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import ApplicationAnalytics from './Components/ApplicationAnalytics/ApplicationAnalytics';
import ExistingOrder from './Components/ExistingOrder/ExistingOrder';
import Header from './Components/Header';
import LeftComponent from './Components/LeftComponent/LeftComponent';
import NewOrder from './Components/NewOrder/NewOrder';
import OrderAnalytics from './Components/OrderAnalytics/OrderAnalytics';
import Transactions from './Components/Transactions/Transactions';

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <div className='messages-container'>
          <div className='resend-progress-container'>
            Sending the Bill to the Registered Mobile Number ....
          </div>
          <div className='resend-success-container'>
            Sent the Bill to the Registered Mobile Number.
          </div>
          <div className='resend-error-container'>
            Unable to send the message.
          </div>
        </div>
        <Header />
        <div className='content-container'>
          <LeftComponent />
          <Routes>
            <Route path='/' element={<NewOrder />}/>
            <Route path='/existing' element={<ExistingOrder />}/>
            <Route path='/order-analytics' element={<OrderAnalytics />}/>
            <Route path='/application-analytics' element={<ApplicationAnalytics />}/>
            <Route path='/transactions' element={<Transactions />}/>
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
