import React, { useEffect } from 'react';
import './LeftComponent.css';

function LeftComponent() {

    const selectClick = (index) => {
        if(index === 1) {
            window.location.href = "/";
        } else if (index === 2) {
            window.location.href = "/existing";
        } else if (index === 3) {
            window.location.href = "/order-analytics";
        } else if (index === 4) {
            window.location.href = "/application-analytics";
        } else if (index === 5) {
            window.location.href = "/transactions";
        }
    }

    function analyticsHover() {
        document.querySelector(".left-analytics-inner").style.display = "flex";
    }

    function analyticsHoverLeave() {
        document.querySelector(".left-analytics-inner").style.display = "none";
    }

    useEffect(() => {
        selectClick();
    }, [])

    return (
        <div className='left-component'>
            <div className='left-select' onClick={event => selectClick(1)}>
                New Order
            </div>
            <div className='left-select' onClick={event => selectClick(2)}>
                Existing Orders
            </div>
            <div className='left-analytics' onMouseEnter={analyticsHover} onMouseLeave={analyticsHoverLeave}>
                Order Analytics
            </div>
            <div className='left-analytics-inner' onMouseEnter={analyticsHover} onMouseLeave={analyticsHoverLeave}>
                <div className='left-select' onClick={event => selectClick(3)}>
                    In House
                </div>
                <div className='left-select' onClick={event => selectClick(4)}>
                    Application
                </div>
            </div>
            <div className='left-select' onClick={event => selectClick(5)}>
                Transactions
            </div>
        </div>
    )
}

export default LeftComponent