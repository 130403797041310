import React, { useEffect, useState } from 'react';
import './Transactions.css';
import { firestore } from '../../firebase/firebaseConfig';
import { collection, getDocs, getDoc, doc, query, where, orderBy } from '@firebase/firestore';

function Transactions() {

    let [transactionDataArray, setTransactionDataArray] = useState([]);

    async function getInitialTransactionData() {
        const querySnapshot =  await getDocs(query(collection(firestore, "transaction_history_global"), orderBy("time", "desc")));
        let transactionDataArray = [];

        querySnapshot.forEach((doc) => {

            let date = new Date(doc.data().time.seconds*1000);

            let transactionObject = {
                "customer_name": doc.data().customer_name,
                "customer_id": doc.data().customer_id,
                "amount": doc.data().amount,
                "doc_id": doc.data().order_doc,
                "order_id": doc.data().order_id,
                "order_time": date,
                "order_total": parseFloat(doc.data().order_total).toFixed(2),
                "via": doc.data().via
            }
            transactionDataArray.push(transactionObject);
        })

        setTransactionDataArray(transactionDataArray);
    }

    const orderIdChange = () => {
        document.querySelector(".order-error").style.display = "none";
    }

    async function getTransactionData(event) {
        event.preventDefault();
        
        let enteredOrderNumber = parseInt(document.querySelector(".existing-order-search").value);
        let full_count = "000000";
        let fullCountLength = 6 - enteredOrderNumber.toString().length;
        let order_id = "LB" + full_count.substr(0, fullCountLength) + enteredOrderNumber.toString();
        let transactionDataArray = [];
        
        const querySnapshot =  await getDocs(query(collection(firestore, "transaction_history_global"), where("order_id", "==", order_id)));

        if(!querySnapshot.empty){
            querySnapshot.forEach((doc) => {

                let date = new Date(doc.data().order_time.seconds*1000);
    
                let transactionObject = {
                    "customer_name": doc.data().customer_name,
                    "customer_id": doc.data().customer_id,
                    "amount": doc.data().amount,
                    "doc_id": doc.data().order_doc,
                    "order_id": doc.data().order_id,
                    "order_time": date,
                    "order_total": parseFloat(doc.data().order_total).toFixed(2),
                    "via": doc.data().via
                }
                transactionDataArray.push(transactionObject);
                setTransactionDataArray(transactionDataArray);
            })
        } else {
            document.querySelector(".order-error").style.display = "block";
            getInitialTransactionData();
        }
    }

    const getTransactionDetails = (event, key) => {
        document.querySelectorAll(".transaction-details-container")[key].style.display = "block";
        document.querySelectorAll(".open")[key].style.display = "none";
        document.querySelectorAll(".close")[key].style.display = "block";
    }

    const closeTransactionDetails = (event, key) => {
        document.querySelectorAll(".transaction-details-container")[key].style.display = "none";
        document.querySelectorAll(".open")[key].style.display = "block";
        document.querySelectorAll(".close")[key].style.display = "none";
    }

    useEffect(() => {
        document.querySelectorAll(".left-select")[4].classList.add("active");
        getInitialTransactionData();
    }, [])

    return (
        <div className='transactions-container'>
            <form onSubmit={getTransactionData} className='transaction-search-container'>
                <input type="search" className='existing-order-search' onChange={orderIdChange} />
                <button type="submit">SEARCH</button>
                <span className='order-error'>* The Order ID doesn't Exist.</span>
            </form>
            <div className='transaction-display-container'>
                {transactionDataArray.map((element, index) => (
                    <div key={index} className='transaction-heads'>
                        <div className="transaction-heads-main">
                            <div className="transaction-heads-main-heads">
                                <div className='heads order-name'>
                                    Name
                                </div>
                                <div className='heads order-id'>
                                    Order ID
                                </div>
                                <div className='heads order-date'>
                                    Time
                                </div>
                                <div className='heads order-total'>
                                    Order Total
                                </div>
                            </div>
                            <div className='transaction-heads-main-content'>
                                <div className='heads order-name'>
                                    {element.customer_name}
                                </div>
                                <div className='heads order-id'>
                                    {element.order_id}
                                </div>
                                <div className='heads order-date'>
                                    {element.order_time.toString()}
                                </div>
                                <div className='heads order-total'>
                                    Rs. {element.order_total}
                                </div>
                            </div>
                            <div className="transaction-details-container">
                                <div className="order-user-container">
                                    <div className="heads order-user-id-head">
                                        Customer ID : 
                                    </div>
                                    <div className='heads order-user-id'>
                                        {element.customer_id}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads order-doc-id-head">
                                        Order Doc ID : 
                                    </div>
                                    <div className='heads order-doc-id'>
                                        {element.doc_id}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads order-status-head">
                                        Via : 
                                    </div>
                                    <div className='heads order-status'>
                                        {element.via}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads store-pin-head">
                                        Amount : 
                                    </div>
                                    <div className='heads store-pin'>
                                        {element.amount}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className='heads transaction-details open' onClick={event => getTransactionDetails(event, index)}>
                            Click Here
                        </button>
                        <button className='heads transaction-details close' onClick={event => closeTransactionDetails(event, index)}>
                            Close
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Transactions