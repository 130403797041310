// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAavUz1bHQ7SNaVwGH6zPNEbR_RRDivHXA",
    authDomain: "laundry-buddy-2938c.firebaseapp.com",
    projectId: "laundry-buddy-2938c",
    storageBucket: "laundry-buddy-2938c.appspot.com",
    messagingSenderId: "906240240297",
    appId: "1:906240240297:web:5f282dd5edbaa2372597b8",
    measurementId: "G-MRMC6CDFGV"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
