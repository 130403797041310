import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Header.css';

function Header() {

    const [expanded, setExpanded] = useState(false);

    const selectClick = (index) => {
        if(index === 1) {
            window.location.href = "/";
        } else if(index === 2) {
            window.location.href = "/existing";
        }
    }

    useEffect(() => {
        selectClick();
    }, [])

    return (
        <Navbar expanded={expanded} expand="lg" bg="dark" variant="dark" id='navbar'>
        <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(true)} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto justify-content-end">
                    <Navbar.Text className='nav-link nav-custom-link' onClick={event => selectClick(1)}>New Order</Navbar.Text>
                    <Navbar.Text className='nav-link nav-custom-link' onClick={event => selectClick(2)}>Existing Orders</Navbar.Text>
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    )
}

export default Header