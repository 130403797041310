import React, { useEffect, useState } from 'react';
import './ExistingOrder.css';
import { firestore, storage } from '../../firebase/firebaseConfig';
import { collection, getDocs, getDoc, doc, query, where, orderBy } from '@firebase/firestore';
import jsPDFInvoiceTemplate, { OutputType } from 'jspdf-invoice-template';
import { ref, uploadBytes, deleteObject } from "firebase/storage";

function ExistingOrder() {

    let [existingOrderDataArray, setExistingOrderDataArray] = useState([]);

    async function getInitialOrderData() {
        const querySnapshot =  await getDocs(query(collection(firestore, "/billing_orders/orders/accepted_orders"), orderBy("order_id", "desc")));
        let orderDataArray = [];

        querySnapshot.forEach((doc) => {

            let order_items_array = [];
            doc.data().order_items.forEach((element) => {
                order_items_array.push(JSON.parse(element));
            })

            let date = new Date(doc.data().order_time.seconds*1000);

            let orderDataObject = {
                "order_items": order_items_array,
                "discount": doc.data().discount,
                "pieces": doc.data().pieces,
                "delivery": doc.data().delivery,
                "express_delivery": doc.data().express_delivery,
                "order_id": doc.data().order_id,
                "order_time": date,
                "order_total": parseFloat(doc.data().order_total).toFixed(2),
                "order_status": doc.data().order_status,
                "store_pin": doc.data().store_pin,
                "order_doc_id": doc.data().order_doc_id,
                "user_name": doc.data().user_name,
                "user_id": doc.data().user_id
            }
            orderDataArray.push(orderDataObject);
        })

        setExistingOrderDataArray(orderDataArray);
    }

    const orderIdChange = () => {
        document.querySelector(".order-error").style.display = "none";
    }

    async function getOrderData(event) {
        event.preventDefault();
        
        let enteredOrderNumber = parseInt(document.querySelector(".existing-order-search").value);
        let full_count = "000000";
        let fullCountLength = 6 - enteredOrderNumber.toString().length;
        let order_id = "LB" + full_count.substr(0, fullCountLength) + enteredOrderNumber.toString();
        let orderDataArray = [];
        
        const querySnapshot =  await getDocs(query(collection(firestore, "/billing_orders/orders/accepted_orders"), where("order_id", "==", order_id)));

        if(!querySnapshot.empty){
            querySnapshot.forEach((doc) => {
                let order_items_array = [];
                doc.data().order_items.forEach((element) => {
                    order_items_array.push(JSON.parse(element));
                })

                let date = new Date(doc.data().order_time.seconds*1000);
    
                let orderDataObject = {
                    "order_items": order_items_array,
                    "discount": doc.data().discount,
                    "delivery": doc.data().delivery,
                    "express_delivery": doc.data().express_delivery,
                    "order_id": doc.data().order_id,
                    "order_time": date,
                    "order_total": parseFloat(doc.data().order_total).toFixed(2),
                    "order_status": doc.data().order_status,
                    "store_pin": doc.data().store_pin,
                    "order_doc_id": doc.data().order_doc_id,
                    "user_name": doc.data().user_name,
                    "user_id": doc.data().user_id
                }
                orderDataArray.push(orderDataObject);
                setExistingOrderDataArray(orderDataArray);
            })
        } else {
            document.querySelector(".order-error").style.display = "block";
            getInitialOrderData();
        }
    }

    const getOrderDetails = (event, key) => {
        document.querySelectorAll(".order-details-container")[key].style.display = "block";
        document.querySelectorAll(".open")[key].style.display = "none";
        document.querySelectorAll(".close")[key].style.display = "block";
        document.querySelectorAll(".pdf")[key].style.display = "block";
        document.querySelectorAll(".resend")[key].style.display = "block";
    }

    const closeOrderDetails = (event, key) => {
        document.querySelectorAll(".order-details-container")[key].style.display = "none";
        document.querySelectorAll(".open")[key].style.display = "block";
        document.querySelectorAll(".close")[key].style.display = "none";
        document.querySelectorAll(".pdf")[key].style.display = "none";
        document.querySelectorAll(".resend")[key].style.display = "none";
    }

    const pdfOrderDetails = async(event, key) => {
        let total = existingOrderDataArray[key].order_total - existingOrderDataArray[key].delivery - existingOrderDataArray[key].express_delivery + existingOrderDataArray[key].discount;

        let document =  await getDoc(doc(firestore, "/billing_users/"+existingOrderDataArray[key].user_id));

        let props = {
            outputType: OutputType.Save,
            returnJsPDFDocObject: true,
            fileName: "LaundryBuddy "+existingOrderDataArray[key].order_id,
            orientationLandscape: false,
            compress: true,
            logo: {
                src: require("../../images/logo.png"),
                type: 'PNG', //optional, when src= data:uri (nodejs case)
                width: 26.66, //aspect ratio = width/height
                height: 26.66,
                margin: {
                    top: 0, //negative or positive num, from the current position
                    left: 0 //negative or positive num, from the current position
                }
            },
            business: {
                name: "Laundry Buddy",
                address: "Electronic City Phase-1, Bengaluru, Karnataka 560100",
                phone: "7022578103",
                email: "contact@laundrybuddy.in",
                website: "https://laundrybuddy.in",
            },
            contact: {
                label: "Invoice issued for:",
                name: existingOrderDataArray[key].user_name,
                address: document.data().address,
                phone: document.data().phone,
                email: document.data().email,
            },
            invoice: {
                label: "OrderID: ",
                num: existingOrderDataArray[key].order_id,
                invGenDate: "Invoice Date:" + existingOrderDataArray[key].order_time.toString(),
                headerBorder: true,
                tableBodyBorder: true,
                header: [
                {
                    title: "#", 
                    style: { 
                    width: 10 
                    } 
                }, 
                { 
                    title: "Service",
                    style: {
                    width: 40
                    } 
                }, 
                { 
                    title: "Category",
                    style: {
                    width: 40
                    } 
                }, 
                { title: "Item"},
                { title: "Quantity"},
                { title: "Price"},
                { title: "Pieces"},
                { title: "Total Cost"}
                ],
                table: Array.from(existingOrderDataArray[key].order_items, (item, index)=>([
                    index + 1,
                    item.Service,
                    item.Category,
                    item.Item,
                    item.Quantity,
                    item.Price,
                    item.Pieces,
                    item.Cost
                ])),
                additionalRows: [{
                    col1: 'SubTotal:',
                    col2: total.toString(),
                    style: {
                        fontSize: 10
                    },
                },
                {
                    col1: '+ Delivery Charge:',
                    col2: existingOrderDataArray[key].delivery.toString(),
                    style: {
                        fontSize: 10 //optional, default 12
                    }
                },
                {
                    col1: '+ Express Delivery Charge:',
                    col2: existingOrderDataArray[key].express_delivery.toString(),
                    style: {
                        fontSize: 10 //optional, default 12
                    }
                },
                {
                    col1: '- Discount:',
                    col2: existingOrderDataArray[key].discount.toString(),
                    style: {
                        fontSize: 10 //optional, default 12
                    }
                },
                {
                    col1: 'Total:',
                    col2: existingOrderDataArray[key].order_total.toString(),
                    style: {
                        fontSize: 14 //optional, default 12
                    }
                }],
            },
            footer: {
                text: "Thank You For Choosing Us.",
            },
            pageEnable: false,
            pageLabel: "",
        };

        jsPDFInvoiceTemplate( props );
    }

    async function resendPdfOrderDetails(event, key) {
        document.querySelector(".messages-container").style.display = "flex";
        document.querySelector(".resend-progress-container").style.display = "block";

        let total = existingOrderDataArray[key].order_total - existingOrderDataArray[key].delivery - existingOrderDataArray[key].express_delivery + existingOrderDataArray[key].discount;

        let document1 =  await getDoc(doc(firestore, "/billing_users/"+existingOrderDataArray[key].user_id));

        let props = {
            outputType: OutputType.Blob,
            returnJsPDFDocObject: true,
            fileName: "LaundryBuddy "+existingOrderDataArray[key].order_id,
            orientationLandscape: false,
            compress: true,
            logo: {
                src: require("../../images/logo.png"),
                type: 'PNG', //optional, when src= data:uri (nodejs case)
                width: 26.66, //aspect ratio = width/height
                height: 26.66,
                margin: {
                    top: 0, //negative or positive num, from the current position
                    left: 0 //negative or positive num, from the current position
                }
            },
            business: {
                name: "Laundry Buddy",
                address: "Electronic City Phase-1, Bengaluru, Karnataka 560100",
                phone: "7022578103",
                email: "contact@laundrybuddy.in",
                website: "https://laundrybuddy.in",
            },
            contact: {
                label: "Invoice issued for:",
                name: existingOrderDataArray[key].user_name,
                address: document1.data().address,
                phone: document1.data().phone,
                email: document1.data().email,
            },
            invoice: {
                label: "OrderID: ",
                num: existingOrderDataArray[key].order_id,
                invGenDate: "Invoice Date:" + existingOrderDataArray[key].order_time.toString(),
                headerBorder: true,
                tableBodyBorder: true,
                header: [
                {
                    title: "#", 
                    style: { 
                    width: 10 
                    } 
                }, 
                { 
                    title: "Service",
                    style: {
                    width: 40
                    } 
                }, 
                { 
                    title: "Category",
                    style: {
                    width: 40
                    } 
                }, 
                { title: "Item"},
                { title: "Quantity"},
                { title: "Price"},
                { title: "Pieces"},
                { title: "Total Cost"}
                ],
                table: Array.from(existingOrderDataArray[key].order_items, (item, index)=>([
                    index + 1,
                    item.Service,
                    item.Category,
                    item.Item,
                    item.Quantity,
                    item.Price,
                    item.Pieces,
                    item.Cost
                ])),
                additionalRows: [{
                    col1: 'SubTotal:',
                    col2: total.toString(),
                    style: {
                        fontSize: 10
                    },
                },
                {
                    col1: '+ Delivery Charge:',
                    col2: existingOrderDataArray[key].delivery.toString(),
                    style: {
                        fontSize: 10 //optional, default 12
                    }
                },
                {
                    col1: '+ Express Delivery Charge:',
                    col2: existingOrderDataArray[key].express_delivery.toString(),
                    style: {
                        fontSize: 10 //optional, default 12
                    }
                },
                {
                    col1: '- Discount:',
                    col2: existingOrderDataArray[key].discount.toString(),
                    style: {
                        fontSize: 10 //optional, default 12
                    }
                },
                {
                    col1: 'Total:',
                    col2: existingOrderDataArray[key].order_total.toString(),
                    style: {
                        fontSize: 14 //optional, default 12
                    }
                }],
            },
            footer: {
                text: "Thank You For Choosing Us.",
            },
            pageEnable: false,
            pageLabel: "",
        };

        let jsPDF_1 = jsPDFInvoiceTemplate( props );
        let pdf_blob = jsPDF_1.blob;
        const storageRef = ref(storage, 'order_invoice/'+existingOrderDataArray[key].order_id);

        uploadBytes(storageRef, pdf_blob).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        });

        let message_json = { 
            messaging_product: "whatsapp", 
            to: document1.data().phone.substring(1), 
            type: "template", 
            template: { 
                name: "laundry_buddy_invoice_pdf", 
                language: { 
                    code: "en_US" 
                },
                components: [
                    {
                        type: "header",
                        parameters: [
                            {
                                type: "document",
                                document: {
                                    filename : "LaundryBuddy "+existingOrderDataArray[key].order_id,
                                    link: "https://firebasestorage.googleapis.com/v0/b/laundry-buddy-2938c.appspot.com/o/order_invoice%2F"+existingOrderDataArray[key].order_id+"?alt=media&token=d97111d7-d15b-4b4d-9c2f-a30aa01927c7"
                                }
                            }
                        ]
                    }
                ]
            } 
        }

        fetch("https://graph.facebook.com/v15.0/104530862478982/messages", {
            method: "post",
            headers: {
                "Authorization": "Bearer EAAHcZBNnoVm4BAIV43RUJSbS9TVMSVn00NWco3uzACqboSWdWXdKSTcjqTvwpSy8FZCrpdYp4gE6Xuy5ZBTtKDU3KPgG5GiwYeDYRt3LJLmthhzieuVwCDgpdyyOhrZBJJZBoPqMnWbZCXFaGOjNKWWns49neLpPETgIZCQ7JxJoqtCzjKtWLfh2OPIPbuCqhOOqQSUkMYZBuwZDZD",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(message_json)
        }).then(res => res.json()).then(json => {
            console.log(json);
            document.querySelector(".resend-progress-container").style.display = "none";
            document.querySelector(".resend-success-container").style.display = "block";
            setTimeout(() => {
                document.querySelector(".messages-container").style.display = "none";
                document.querySelector(".resend-success-container").style.display = "none";
            }, 2000);
        }).catch(error => {
            console.log(error);
            document.querySelector(".resend-progress-container").style.display = "none";
            document.querySelector(".resend-error-container").style.display = "block";
            setTimeout(() => {
                document.querySelector(".messages-container").style.display = "none";
                document.querySelector(".resend-success-container").style.display = "none";
            }, 2000);
        })

        setTimeout(function(){
            const desertRef = ref(storage, 'order_invoice/'+existingOrderDataArray[key].order_id);
            deleteObject(desertRef).then(() => {
                console.log("File deleted successfully");
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });
        }, 5000);
    }

    useEffect(() => {
        document.querySelectorAll(".left-select")[1].classList.add("active");
        getInitialOrderData();
    }, [])

    return (
        <div className='existing-order-container'>
            <form onSubmit={getOrderData} className='existing-order-search-container'>
                <input type="search" className='existing-order-search' onChange={orderIdChange} />
                <button type="submit">SEARCH</button>
                <span className='order-error'>* The Order ID doesn't Exist.</span>
            </form>
            <div className='existing-order-display-container'>
                {existingOrderDataArray.map((element, index) => (
                    <div key={index} className='existing-order-heads'>
                        <div className="existing-heads-main">
                            <div className="existing-heads-main-heads">
                                <div className='heads order-name'>
                                    Name
                                </div>
                                <div className='heads order-id'>
                                    Order ID
                                </div>
                                <div className='heads order-date'>
                                    Order Date
                                </div>
                                <div className='heads order-total'>
                                    Order Total
                                </div>
                            </div>
                            <div className='existing-heads-main-content'>
                                <div className='heads order-name'>
                                    {element.user_name}
                                </div>
                                <div className='heads order-id'>
                                    {element.order_id}
                                </div>
                                <div className='heads order-date'>
                                    {element.order_time.toString()}
                                </div>
                                <div className='heads order-total'>
                                    Rs. {element.order_total}
                                </div>
                            </div>
                            <div className="order-details-container">
                                <div className="order-user-container">
                                    <div className="heads order-user-id-head">
                                        User ID : 
                                    </div>
                                    <div className='heads order-user-id'>
                                        {element.user_id}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads order-doc-id-head">
                                        Order Doc ID : 
                                    </div>
                                    <div className='heads order-doc-id'>
                                        {element.order_doc_id}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads order-status-head">
                                        Order Status : 
                                    </div>
                                    <div className='heads order-status'>
                                        {element.order_status}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads store-pin-head">
                                        Store Pin : 
                                    </div>
                                    <div className='heads store-pin'>
                                        {element.store_pin}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads express-delivery-head">
                                        Express Delivery Charge : 
                                    </div>
                                    <div className='heads express-delivery'>
                                        {element.express_delivery}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads discount-head">
                                        Discount : 
                                    </div>
                                    <div className='heads discount'>
                                        {element.discount}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads order-items-head">
                                        Order Items : 
                                    </div>
                                </div>
                                <div>
                                    <div className='order-user-container'>
                                        <div className='heads pricing-service'>
                                            Service
                                        </div>
                                        <div className='heads pricing-category'>
                                            Category
                                        </div>
                                        <div className='heads pricing-item'>
                                            Item
                                        </div>
                                        <div className='heads pricing-quantity'>
                                            Quantity & Price
                                        </div>
                                        <div className='heads pricing-pieces'>
                                            Pieces
                                        </div>
                                        <div className='heads pricing-cost'>
                                            Cost
                                        </div>
                                    </div>
                                    <div className="order-user-container">
                                        {element.order_items.map((element1, index) => (
                                            <div key={index}>
                                                <div className='heads pricing-service'>
                                                    {element1.Service}
                                                </div>
                                                <div className='heads pricing-category'>
                                                    {element1.Category}
                                                </div>
                                                <div className='heads pricing-item'>
                                                    {element1.Item}
                                                </div>
                                                <div className='heads pricing-quantity'>
                                                    {element1.Quantity} Qty. | Rs. {element1.Price}
                                                    {element1.Suffix}
                                                </div>
                                                <div className='heads pricing-pieces'>
                                                    {element1.Pieces}
                                                </div>
                                                <div className='heads pricing-cost'>
                                                    Rs. {element1.Cost}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className='heads order-details open' onClick={event => getOrderDetails(event, index)}>
                            Click Here
                        </button>
                        <div className='close-div-buttons'>
                            <button className='heads order-details close' onClick={event => closeOrderDetails(event, index)}>
                                Close
                            </button>
                            <button className='heads order-details pdf' onClick={event => pdfOrderDetails(event, index)}>
                                PDF
                            </button>
                            <button className='heads order-details resend' onClick={event => resendPdfOrderDetails(event, index)}>
                                Resend
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ExistingOrder