import React, {useEffect, useState} from 'react';
import './OrderAnalytics.css';
import { firestore } from '../../firebase/firebaseConfig';
import { collection, getDocs, query, orderBy, doc, deleteDoc, updateDoc } from '@firebase/firestore';

function OrderAnalytics() {
    let [dataArray, setDataArray] = useState([]);
    let [billedDataArray, setBilledDataArray] = useState([]);
    let [avgOrderValue, setAvgOrderValue] = useState(0);
    let [avgItemValue, setAvgItemValue] = useState(0);
    let [totalUnitsValue, setTotalUnitsValue] = useState(0);
    let [totalOrdersValue, setTotalOrdersValue] = useState(0);
    let [totalAmountValue, setTotalAmountValue] = useState(0);

    let [avgUserOrderValue, setAvgUserOrderValue] = useState(0);
    let [avgUserItemValue, setAvgUserItemValue] = useState(0);
    let [totalUserUnitsValue, setTotalUserUnitsValue] = useState(0);
    let [totalUserOrdersValue, setTotalUserOrdersValue] = useState(0);
    let [totalUserAmountValue, setTotalUserAmountValue] = useState(0);

    async function getOrderData() {
        document.querySelector(".order-analytics-display-container").style.display = "flex";
        document.querySelector(".order-analytics-billed-users-display-container").style.display = "none";
        document.querySelector(".order-total-head").style.display = "block";
        document.querySelector(".order-users").style.display = "none";
        document.querySelectorAll(".billed-user-none")[0].style.display = "block";
        document.querySelectorAll(".billed-user-none")[1].style.display = "block";
        document.querySelectorAll(".billed-user-none")[2].style.display = "block";
        document.querySelectorAll(".billed-user-none")[3].style.display = "block";

        const querySnapshot =  await getDocs(query(collection(firestore, "/billing_orders/orders/accepted_orders"), orderBy("order_id", "desc")));
        
        let orderDataArray = [];
        let total_order = 0;
        let total_cost = 0;
        let total_items = 0;
        let total_amount = 0;
        let prior_date = new Date().setDate(new Date().getDate() - 30);

        querySnapshot.forEach((doc) => {
            let stored_date = doc.data().order_time.seconds*1000;

            if(stored_date >= prior_date) {
                let order_items_array = [];
                total_order = total_order + 1;
                total_amount = total_amount + doc.data().order_total;
                doc.data().order_items.forEach((element) => {
                    order_items_array.push(JSON.parse(element));
                })

                let date = new Date(doc.data().order_time.seconds*1000);

                order_items_array.forEach((element1) => {
                    total_items = total_items + parseFloat(element1.Quantity);
                    total_cost = total_cost + parseFloat(element1.Cost);
                })

                let avgOrder_raw = total_amount/total_order;
                let avgOrder = avgOrder_raw.toFixed(2);
                let avgItem_raw = total_cost/total_items;
                let avgItem = avgItem_raw.toFixed(2);
                setAvgOrderValue(avgOrder);
                setAvgItemValue(avgItem);
                setTotalUnitsValue(total_items);
                setTotalOrdersValue(total_order);
                setTotalAmountValue(parseFloat(total_amount).toFixed(2));

                let orderDataObject = {
                    "order_items": order_items_array,
                    "discount": doc.data().discount,
                    "express_delivery": doc.data().express_delivery,
                    "order_id": doc.data().order_id,
                    "order_time": date,
                    "order_total": parseFloat(doc.data().order_total).toFixed(2),
                    "order_status": doc.data().order_status,
                    "store_pin": doc.data().store_pin,
                    "order_doc_id": doc.data().order_doc_id,
                    "user_name": doc.data().user_name,
                    "user_id": doc.data().user_id
                }
                orderDataArray.push(orderDataObject);
            }
        })
        setDataArray(orderDataArray);
    }

    async function getBilledUsersData() {
        document.querySelector(".order-analytics-display-container").style.display = "none";
        document.querySelector(".order-analytics-billed-users-display-container").style.display = "flex";
        document.querySelectorAll(".order-total-head")[0].style.display = "none";
        document.querySelectorAll(".order-total-head")[1].style.display = "none";
        document.querySelectorAll(".order-users")[0].style.display = "block";
        document.querySelectorAll(".order-users")[1].style.display = "block";
        document.querySelectorAll(".billed-user-none")[0].style.display = "none";
        document.querySelectorAll(".billed-user-none")[1].style.display = "none";
        document.querySelectorAll(".billed-user-none")[2].style.display = "none";
        document.querySelectorAll(".billed-user-none")[3].style.display = "none";
        billedDataArray = [];

        const querySnapshot =  await getDocs(query(collection(firestore, "/billing_users"), orderBy("name", "asc")));
        let total_users = 0;

        querySnapshot.forEach((doc) => {
            total_users = total_users + 1;
            getUserInnerOrderDetails(doc.id, doc.data());
        })

        let avgOrderFinal = totalAmountValue/total_users;
        setAvgOrderValue(avgOrderFinal.toFixed(2));
        setTotalOrdersValue(total_users);
    }

    async function getUserInnerOrderDetails(user_id, user_data) {
        const querySnapshot =  await getDocs(query(collection(firestore, "/billing_users/" + user_id + "/user_order"), orderBy("order_id", "desc")));

        let orderDataArray = [];
        let total_order = 0;
        let total_cost = 0;
        let total_items = 0;
        let total_amount = 0;
        let analyticsObject = {};
        let billedUserArray = billedDataArray;

        querySnapshot.forEach((doc) => {
            let order_items_array = [];
            total_order = total_order + 1;
            total_amount = total_amount + doc.data().order_total;
            doc.data().order_items.forEach((element) => {
                order_items_array.push(JSON.parse(element));
            })

            let date = new Date(doc.data().order_time.seconds*1000);

            order_items_array.forEach((element1) => {
                total_items = total_items + parseInt(element1.Quantity);
                total_cost = total_cost + element1.Cost;
            })

            let avgOrder_raw = total_amount/total_order;
            let avgOrder = avgOrder_raw.toFixed(2);
            let avgItem_raw = total_cost/total_items;
            let avgItem = avgItem_raw.toFixed(2);
            setAvgUserOrderValue(avgOrder);
            setAvgUserItemValue(avgItem);
            setTotalUserUnitsValue(total_items);
            setTotalUserOrdersValue(total_order);
            setTotalUserAmountValue(total_amount.toFixed(2));

            analyticsObject = {
                "avg_order_value": avgOrder,
                "avg_item_value": avgItem,
                "total_units": total_items,
                "total_orders": total_order,
                "total_amount": total_amount
            }
            
            let orderDataObject = {
                "order_items": order_items_array,
                "discount": doc.data().discount,
                "express_delivery": doc.data().express_delivery,
                "order_id": doc.data().order_id,
                "order_time": date,
                "order_total": parseFloat(doc.data().order_total).toFixed(2),
                "order_status": doc.data().order_status,
                "store_pin": doc.data().store_pin,
                "order_doc_id": doc.id,
                "user_id": doc.data().user_id
            }
            orderDataArray.push(orderDataObject);
        })

        let billedUserObject = {
            "name": user_data.name,
            "phone": user_data.phone,
            "email": user_data.email,
            "address": user_data.address,
            "id": user_id,
            "user_analytics": analyticsObject,
            "user_orders": orderDataArray
        }
        billedUserArray.push(billedUserObject);
        setBilledDataArray(billedUserArray);
    }

    async function getUserOrderDetails(event){
        event.nativeEvent.path[1].parentNode.parentNode.childNodes.forEach((element) => {
            element.style.display = "flex";
        });
        event.target.style.display = "none";
        console.log(event.target, event.target.parentNode.childNodes);
        event.target.parentNode.childNodes[1].style.display = "block";
    }

    const closeUserOrderDetails = (event) => {
        event.nativeEvent.path[1].parentNode.parentNode.childNodes.forEach((element) => {
            element.style.display = "none";
        });
        event.nativeEvent.path[1].parentNode.parentNode.childNodes[0].style.display = "flex"
        event.nativeEvent.path[1].childNodes[0].style.display = "block";
        event.nativeEvent.path[1].childNodes[1].style.display = "none";
    }

    const getOrderDetails = (event) => {
        event.nativeEvent.path[1].parentNode.childNodes[0].childNodes[2].style.display = "block";
        event.nativeEvent.path[1].childNodes[0].style.display = "none";
        event.nativeEvent.path[1].childNodes[1].style.display = "block";
    }

    const closeOrderDetails = (event) => {
        event.nativeEvent.path[1].parentNode.childNodes[0].childNodes[2].style.display = "none";
        event.nativeEvent.path[1].childNodes[0].style.display = "block";
        event.nativeEvent.path[1].childNodes[1].style.display = "none";
    }

    const getOrderDetailsUser = (event) => {
        event.nativeEvent.path[1].childNodes[0].childNodes[2].style.display = "block";
        event.nativeEvent.path[1].childNodes[1].style.display = "none";
        event.nativeEvent.path[1].childNodes[2].style.display = "block";
    }

    const closeOrderDetailsUser = (event) => {
        event.nativeEvent.path[1].childNodes[0].childNodes[2].style.display = "none";
        event.nativeEvent.path[1].childNodes[1].style.display = "block";
        event.nativeEvent.path[1].childNodes[2].style.display = "none";
    }

    function orderDetailsEdit(event) {
        console.log(event.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes);
        event.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes.forEach((element) => {
            element.setAttribute("contentEditable", true);
            element.style.border = "1px solid white";
        })
        event.target.style.display = "none";
        event.target.parentNode.childNodes[3].style.display = "block";
    }

    async function orderDetailsSave(event, user_id) {
        let name = event.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes[0].innerHTML;
        let phone = event.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes[1].innerHTML;
        let email = event.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes[2].innerHTML;
        let address = event.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes[3].innerHTML;
        await updateDoc(doc(firestore, "/billing_users/" + user_id), {
            name: name,
            phone: phone,
            email: email,
            address: address
        })
        event.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes.forEach((element) => {
            element.setAttribute("contentEditable", false);
            element.style.border = "none";
        })
        event.target.style.display = "none";
        event.target.parentNode.childNodes[2].style.display = "block";
    }

    const analyticsOrderSelect = () => {
        let selectedValue = document.querySelector("#analyticsOrderSelect").value;

        if(selectedValue === "billed_orders") {
            getOrderData();
        } else if(selectedValue === "billed_users") {
            getBilledUsersData();
        }
    }

    const billedOrderSelect = () => {
        let selectedValue = document.querySelector("#billedOrderSelect").value;

        if(selectedValue === "order_time") {
            document.querySelector(".dateFilterDiv").style.display = "flex";
        } else {
            document.querySelector(".order-analytics-search-container form").style.display = "none";
            getOrderData();
        }
    }

    const dateHouseFilterSelect = async(event) => {
        event.preventDefault();

        const querySnapshot =  await getDocs(query(collection(firestore, "/billing_orders/orders/accepted_orders"), orderBy("order_id", "desc")));
        let orderDataArray = [];
        let total_order = 0;
        let total_cost = 0;
        let total_items = 0;
        let total_amount = 0;

        let date = "";
        let fromDate = new Date(document.querySelector("#dateFrom").value).setHours(0, 0, 0,0);
        let toDate = new Date(document.querySelector("#dateTo").value).setHours(0, 0, 0,0);

        querySnapshot.forEach((doc) => {
            date = doc.data().order_time.seconds*1000;
            if(date >= fromDate && date <= toDate) {
                let order_items_array = [];
                total_order = total_order + 1;
                total_amount = total_amount + doc.data().order_total;
                doc.data().order_items.forEach((element) => {
                    order_items_array.push(JSON.parse(element));
                })

                let date = new Date(doc.data().order_time.seconds*1000);

                order_items_array.forEach((element1) => {
                    total_items = total_items + parseFloat(element1.Quantity);
                    total_cost = total_cost + parseFloat(element1.Cost);
                })

                let avgOrder_raw = total_amount/total_order;
                let avgOrder = avgOrder_raw.toFixed(2);
                let avgItem_raw = total_cost/total_items;
                let avgItem = avgItem_raw.toFixed(2);
                setAvgOrderValue(avgOrder);
                setAvgItemValue(avgItem);
                setTotalUnitsValue(total_items);
                setTotalOrdersValue(total_order);
                setTotalAmountValue(total_amount.toFixed(2));

                let orderDataObject = {
                    "order_items": order_items_array,
                    "discount": doc.data().discount,
                    "express_delivery": doc.data().express_delivery,
                    "order_id": doc.data().order_id,
                    "order_time": date,
                    "order_total": parseFloat(doc.data().order_total).toFixed(2),
                    "order_status": doc.data().order_status,
                    "store_pin": doc.data().store_pin,
                    "order_doc_id": doc.data().order_doc_id,
                    "user_name": doc.data().user_name,
                    "user_id": doc.data().user_id
                }
                orderDataArray.push(orderDataObject);
            }
        })
        setDataArray(orderDataArray);
    }

    async function deleteOrderDetails(user_id, doc_id, order_id) {
        let confirmDelete = prompt("Enter "+order_id+" to confirm the delete of the order.");

        if(confirmDelete === order_id) {
            await deleteDoc(doc(firestore, "billing_orders/orders/accepted_orders", doc_id));
            getOrderData();
            await deleteDoc(doc(firestore, "billing_users/"+user_id+"/user_order", doc_id));
        } else if(confirmDelete !== order_id) {
            alert("You Entered the wrong id.");
        }
    }

    useEffect(() => {
        document.querySelector(".left-analytics").classList.add("active");
        getOrderData();
    }, [])

    return (
        <div className='order-analytics-container'>
            <div className='order-analytics-search-container'>
                <select id='analyticsOrderSelect' onChange={analyticsOrderSelect}>
                    <option value="billed_orders">Billed Orders</option>
                    <option value="billed_users">Billed Users</option>
                </select>
                <select id='billedOrderSelect' onChange={billedOrderSelect}>
                    <option value="">Select Filter</option>
                    <option value="order_time">Order Time</option>
                </select>
                <form className='dateFilterDiv'>
                    <label for="dateFrom">From: </label>
                    <input type="date" name="" id='dateFrom' required />
                    <label for="dateTo">To:</label>
                    <input type="date" name="" id='dateTo' required />
                    <button onClick={dateHouseFilterSelect} type="submit" className='analytics-button active'>Apply</button>
                </form>
            </div>
            <div className='order-analytics-display-container'>
                {dataArray.map((element, index) => (
                    <div key={index} className='order-analytics-heads'>
                        <div className="order-analytics-heads-main">
                            <div className="order-analytics-heads-main-heads">
                                <div className='heads order-name'>
                                    Name
                                </div>
                                <div className='heads order-id'>
                                    Order ID
                                </div>
                                <div className='heads order-date'>
                                    Order Date
                                </div>
                                <div className='heads order-total'>
                                    Order Total
                                </div>
                            </div>
                            <div className='order-analytics-heads-main-content'>
                                <div className='heads order-name'>
                                    {element.user_name}
                                </div>
                                <div className='heads order-id'>
                                    {element.order_id}
                                </div>
                                <div className='heads order-date'>
                                    {element.order_time.toString()}
                                </div>
                                <div className='heads order-total'>
                                    Rs. {element.order_total}
                                </div>
                            </div>
                            <div className="order-details-container">
                                <div className="order-user-container">
                                    <div className="heads order-user-id-head">
                                        User ID : 
                                    </div>
                                    <div className='heads order-user-id'>
                                        {element.user_id}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads order-doc-id-head">
                                        Order Doc ID : 
                                    </div>
                                    <div className='heads order-doc-id'>
                                        {element.order_doc_id}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads order-status-head">
                                        Order Status : 
                                    </div>
                                    <div className='heads order-status'>
                                        {element.order_status}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads store-pin-head">
                                        Store Pin : 
                                    </div>
                                    <div className='heads store-pin'>
                                        {element.store_pin}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads express-delivery-head">
                                        Express Delivery Charge : 
                                    </div>
                                    <div className='heads express-delivery'>
                                        {element.express_delivery}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads discount-head">
                                        Discount : 
                                    </div>
                                    <div className='heads discount'>
                                        {element.discount}
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads order-items-head">
                                        Order Items : 
                                    </div>
                                </div>
                                <div>
                                    <div className='order-user-container'>
                                        <div className='heads pricing-service'>
                                            Service
                                        </div>
                                        <div className='heads pricing-category'>
                                            Category
                                        </div>
                                        <div className='heads pricing-item'>
                                            Item
                                        </div>
                                        <div className='heads pricing-quantity'>
                                            Quantity & Price
                                        </div>
                                        <div className='heads pricing-pieces'>
                                            Pieces
                                        </div>
                                        <div className='heads pricing-cost'>
                                            Cost
                                        </div>
                                    </div>
                                    <div className="order-user-container">
                                        {element.order_items.map((element1, index) => (
                                            <div key={index}>
                                                <div className='heads pricing-service'>
                                                    {element1.Service}
                                                </div>
                                                <div className='heads pricing-category'>
                                                    {element1.Category}
                                                </div>
                                                <div className='heads pricing-item'>
                                                    {element1.Item}
                                                </div>
                                                <div className='heads pricing-quantity'>
                                                    {element1.Quantity} Qty. | Rs. {element1.Price}
                                                    {element1.Suffix}
                                                </div>
                                                <div className='heads pricing-pieces'>
                                                    {element1.Pieces}
                                                </div>
                                                <div className='heads pricing-cost'>
                                                    Rs. {element1.Cost}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='order-details-button-container'>
                            <button className='heads order-details open' onClick={getOrderDetails}>
                                Click Here
                            </button>
                            <button className='heads order-details close' onClick={closeOrderDetails}>
                                Close
                            </button>
                            <button className='heads order-details delete' onClick={event => deleteOrderDetails(element.user_id, element.order_doc_id, element.order_id)}>
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className='order-analytics-billed-users-display-container'>
                {billedDataArray.map((element) => (
                    <div key={element.id}>
                        <div className='order-analytics-heads'>
                            <div className="order-analytics-heads-main">
                                <div className="order-analytics-heads-main-heads">
                                    <div className='heads order-name'>
                                        Name
                                    </div>
                                    <div className='heads order-phone'>
                                        Phone Number
                                    </div>
                                    <div className='heads order-email'>
                                        Email ID
                                    </div>
                                    <div className='heads order-address'>
                                        Address
                                    </div>
                                </div>
                                <div className='order-analytics-heads-main-content'>
                                    <div className='heads order-name'>
                                        {element.name}
                                    </div>
                                    <div className='heads order-phone'>
                                        {element.phone}
                                    </div>
                                    <div className='heads order-email'>
                                        {element.email}
                                    </div>
                                    <div className='heads order-address'>
                                        {element.address}
                                    </div>
                                </div>
                            </div>
                            <div className='order-details-button-container'>
                                <div className='heads order-details open' onClick={event => getUserOrderDetails(event, element.id)}>
                                    Click Here
                                </div>
                                <div className='heads order-details close' onClick={event => closeUserOrderDetails(event)}>
                                    Close
                                </div>
                                <div className='heads order-details edit' onClick={event => orderDetailsEdit(event)}>
                                    Edit
                                </div>
                                <div className='heads order-details save' onClick={event => orderDetailsSave(event, element.id)}>
                                    Save
                                </div>
                            </div>
                        </div>
                        {element.user_orders.map((element1) => (
                            <div key={element1.order_id} className='user-analytics-heads'>
                                <div className="order-analytics-heads-main">
                                    <div className="order-analytics-heads-main-heads">
                                        <div className='heads order-name'>
                                            User ID
                                        </div>
                                        <div className='heads order-id'>
                                            Order ID
                                        </div>
                                        <div className='heads order-date'>
                                            Order Date
                                        </div>
                                        <div className='heads order-total'>
                                            Order Total
                                        </div>
                                    </div>
                                    <div className='order-analytics-heads-main-content'>
                                        <div className='heads order-name'>
                                            {element1.user_id}
                                        </div>
                                        <div className='heads order-id'>
                                            {element1.order_id}
                                        </div>
                                        <div className='heads order-date'>
                                            {element1.order_time.toString()}
                                        </div>
                                        <div className='heads order-total'>
                                            Rs. {element1.order_total}
                                        </div>
                                    </div>
                                    <div className="order-details-container">
                                        <div className="order-user-container">
                                            <div className="heads order-doc-id-head">
                                                Order Doc ID : 
                                            </div>
                                            <div className='heads order-doc-id'>
                                                {element1.order_doc_id}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads order-status-head">
                                                Order Status : 
                                            </div>
                                            <div className='heads order-status'>
                                                {element1.order_status}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads store-pin-head">
                                                Store Pin : 
                                            </div>
                                            <div className='heads store-pin'>
                                                {element1.store_pin}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads express-delivery-head">
                                                Express Delivery Charge : 
                                            </div>
                                            <div className='heads express-delivery'>
                                                {element1.express_delivery}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads discount-head">
                                                Discount : 
                                            </div>
                                            <div className='heads discount'>
                                                {element1.discount}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads order-items-head">
                                                Order Items : 
                                            </div>
                                        </div>
                                        <div>
                                            <div className='order-user-container'>
                                                <div className='heads pricing-service'>
                                                    Service
                                                </div>
                                                <div className='heads pricing-category'>
                                                    Category
                                                </div>
                                                <div className='heads pricing-item'>
                                                    Item
                                                </div>
                                                <div className='heads pricing-quantity'>
                                                    Quantity & Price
                                                </div>
                                                <div className='heads pricing-pieces'>
                                                    Pieces
                                                </div>
                                                <div className='heads pricing-cost'>
                                                    Cost
                                                </div>
                                            </div>
                                            <div className="order-user-container">
                                                {element1.order_items.map((element2, index) => (
                                                    <div key={index}>
                                                        <div className='heads pricing-service'>
                                                            {element2.Service}
                                                        </div>
                                                        <div className='heads pricing-category'>
                                                            {element2.Category}
                                                        </div>
                                                        <div className='heads pricing-item'>
                                                            {element2.Item}
                                                        </div>
                                                        <div className='heads pricing-quantity'>
                                                            {element2.Quantity} Qty. | Rs. {element2.Price}
                                                            {element2.Suffix}
                                                        </div>
                                                        <div className='heads pricing-pieces'>
                                                            {element2.Pieces}
                                                        </div>
                                                        <div className='heads pricing-cost'>
                                                            Rs. {element2.Cost}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='heads order-details open' onClick={getOrderDetailsUser}>
                                    Click Here
                                </div>
                                <div className='heads order-details close' onClick={closeOrderDetailsUser}>
                                    Close
                                </div>
                            </div>
                        ))}
                        <div className="order-analysis-container">
                            <div className="order-analysis-heads">
                                <div className='heads order-avg-value'>
                                    Avg Order Value
                                </div>
                                <div className='heads order-item-value'>
                                    Avg Item Value
                                </div>
                                <div className='heads order-units'>
                                    Total Units
                                </div>
                                <div className='heads order-total'>
                                    Total Orders
                                </div>
                                <div className='heads order-total-amount'>
                                    Total Amount
                                </div>
                            </div>
                            <div className='order-analysis-main-content'>
                                <div className='heads order-name'>
                                    Rs. {element.user_analytics.avg_order_value}
                                </div>
                                <div className='heads order-id'>
                                    Rs. {element.user_analytics.avg_item_value}
                                </div>
                                <div className='heads order-date'>
                                    {element.user_analytics.total_units}
                                </div>
                                <div className='heads order-total'>
                                    {element.user_analytics.total_orders}
                                </div>
                                <div className='heads order-total'>
                                    Rs. {element.user_analytics.total_amount}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="order-analysis-container">
                <div className="order-analysis-heads">
                    <div className='heads order-avg-value order-total-head'>
                        Avg Order Value
                    </div>
                    <div className='heads order-users'>
                        Avg User Value
                    </div>
                    <div className='heads order-item-value billed-user-none'>
                        Avg Item Value
                    </div>
                    <div className='heads order-units billed-user-none'>
                        Total Units
                    </div>
                    <div className='heads order-total-head'>
                        Total Orders
                    </div>
                    <div className='heads order-users'>
                        Total Users
                    </div>
                    <div className='heads order-total-amount'>
                        Total Amount
                    </div>
                </div>
                <div className='order-analysis-main-content'>
                    <div className='heads order-name'>
                        Rs. {avgOrderValue}
                    </div>
                    <div className='heads order-id billed-user-none'>
                        Rs. {avgItemValue}
                    </div>
                    <div className='heads order-date billed-user-none'>
                        {totalUnitsValue.toFixed(2)}
                    </div>
                    <div className='heads order-total'>
                        {totalOrdersValue}
                    </div>
                    <div className='heads order-total'>
                        Rs. {totalAmountValue}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderAnalytics