import React, {useEffect, useState} from 'react';
import './ApplicationAnalytics.css';
import { firestore } from '../../firebase/firebaseConfig';
import { collection, getDocs, query, orderBy, doc, deleteDoc, where } from '@firebase/firestore';

function ApplicationAnalytics() {
    let [dataArray, setDataArray] = useState([]);
    let [avgOrderValue, setAvgOrderValue] = useState(0);
    let [avgItemValue, setAvgItemValue] = useState(0);
    let [totalUnitsValue, setTotalUnitsValue] = useState(0);
    let [totalOrdersValue, setTotalOrdersValue] = useState(0);
    let [totalAmountValue, setTotalAmountValue] = useState(0);
    let [pinCodes, setPinCodes] = useState([]);

    async function getOrderData() {
        const querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/upcoming_orders_id/upcoming_orders"), orderBy("order_id", "desc")));
        
        let orderDataArray = [];
        let total_order = 0;
        let total_cost = 0;
        let total_items = 0;
        let total_amount = 0;
        let prior_date = new Date().setDate(new Date().getDate() - 30);
        
        querySnapshot.forEach((doc) => {
            let cart_items_array = [];
            let cart_items1 = doc.data().cart_items.split("},{");
            cart_items1.forEach((element, index) => {
                if(cart_items1.length >= 2){
                    if(index === 0) {
                        let array_item = "{" + element.replace("[{", "") + "}";
                        cart_items_array.push(array_item);
                    } else if(index === cart_items1.length-1) {
                        let array_item = "{" + element.replace("}]", "") + "}";
                        cart_items_array.push(array_item);
                    } else {
                        let array_item = "{" + element + "}";
                        cart_items_array.push(array_item);
                    }
                } 
                else {
                    let array_item1 = "{" + element.replace("[{", "");
                    let array_item = array_item1.replace("}]", "") + "}";
                    cart_items_array.push(array_item);
                }
            })

            let stored_date = doc.data().order_time.seconds*1000;

            if(stored_date >= prior_date) {
                let order_items_array = [];
                total_order = total_order + 1;
                total_amount = total_amount + doc.data().order_total;
                cart_items_array.forEach((element) => {
                    order_items_array.push(JSON.parse(element));
                })

                let order_date = new Date(doc.data().order_time.seconds*1000);
                let pick_date = new Date(doc.data().pickup_date.seconds*1000);

                order_items_array.forEach((element1) => {
                    total_items = total_items + parseInt(element1.count);
                    total_cost = total_cost + (element1.price * element1.count);
                })

                let avgOrder_raw = total_amount/total_order;
                let avgOrder = avgOrder_raw.toFixed(2);
                let avgItem_raw = total_cost/total_items;
                let avgItem = avgItem_raw.toFixed(2);
                setAvgOrderValue(avgOrder);
                setAvgItemValue(avgItem);
                setTotalUnitsValue(total_items);
                setTotalOrdersValue(total_order);
                setTotalAmountValue(parseFloat(total_amount).toFixed(2));

                let orderDataObject = {
                    "order_items": order_items_array,
                    "comments": doc.data().comments,
                    "delivery_charge": doc.data().delivery_charge,
                    "delivery_within": doc.data().delivery_within,
                    "discount": doc.data().discount.toFixed(2),
                    "express_delivery": doc.data().express_delivery,
                    "name": doc.data().name,
                    "order_doc": doc.data().order_doc,
                    "order_id": doc.data().order_id,
                    "order_time": order_date,
                    "order_total": parseFloat(doc.data().order_total).toFixed(2),
                    "pickup_date": pick_date,
                    "status": doc.data().status,
                    "timeslot": doc.data().timeslot, 
                    "user_id": doc.data().user_id,
                    "store_pin": "",
                    "doc_id": doc.id
                }
                orderDataArray.push(orderDataObject);
            }
        })
        setDataArray(orderDataArray);
    }

    async function getAcceptedOrderData() {
        const querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/accepted_orders_id/accepted_orders"), orderBy("order_id", "desc")));
        
        let orderDataArray = [];
        let total_order = 0;
        let total_cost = 0;
        let total_items = 0;
        let total_amount = 0;
        let prior_date = new Date().setDate(new Date().getDate() - 30);
        
        querySnapshot.forEach((doc) => {
            let cart_items_array = [];
            let cart_items1 = doc.data().cart_items.split("},{");
            cart_items1.forEach((element, index) => {
                if(cart_items1.length >= 2){
                    if(index === 0) {
                        let array_item = "{" + element.replace("[{", "") + "}";
                        cart_items_array.push(array_item);
                    } else if(index === cart_items1.length-1) {
                        let array_item = "{" + element.replace("}]", "") + "}";
                        cart_items_array.push(array_item);
                    } else {
                        let array_item = "{" + element + "}";
                        cart_items_array.push(array_item);
                    }
                } else {
                    let array_item = "{" + element.replace("[{", "").replace("}]", "") + "}";
                    cart_items_array.push(array_item);
                }
            })

            let stored_date = doc.data().order_time.seconds*1000;

            if(stored_date >= prior_date) {
                let order_items_array = [];
                total_order = total_order + 1;
                total_amount = total_amount + doc.data().order_total;
                cart_items_array.forEach((element) => {
                    order_items_array.push(JSON.parse(element));
                })

                let order_date = new Date(doc.data().order_time.seconds*1000);
                let pick_date = new Date(doc.data().pickup_date.seconds*1000);

                order_items_array.forEach((element1) => {
                    total_items = total_items + parseInt(element1.count);
                    total_cost = total_cost + (element1.price * element1.count);
                })

                let avgOrder_raw = total_amount/total_order;
                let avgOrder = avgOrder_raw.toFixed(2);
                let avgItem_raw = total_cost/total_items;
                let avgItem = avgItem_raw.toFixed(2);
                setAvgOrderValue(avgOrder);
                setAvgItemValue(avgItem);
                setTotalUnitsValue(total_items);
                setTotalOrdersValue(total_order);
                setTotalAmountValue(parseFloat(total_amount).toFixed(2));

                let orderDataObject = {
                    "order_items": order_items_array,
                    "comments": doc.data().comments,
                    "delivery_charge": doc.data().delivery_charge,
                    "delivery_within": doc.data().delivery_within,
                    "discount": doc.data().discount.toFixed(2),
                    "express_delivery": doc.data().express_delivery,
                    "name": doc.data().name,
                    "order_doc": doc.data().order_doc,
                    "order_id": doc.data().order_id,
                    "order_time": order_date,
                    "order_total": parseFloat(doc.data().order_total).toFixed(2),
                    "pickup_date": pick_date,
                    "status": doc.data().status,
                    "timeslot": doc.data().timeslot, 
                    "user_id": doc.data().user_id,
                    "store_pin": doc.data().store_pin,
                    "doc_id": doc.id
                }
                orderDataArray.push(orderDataObject);
            }
        })
        setDataArray(orderDataArray);
    }

    async function getWarehouseOrderData() {
        const querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/in_warehouse_id/in_warehouse"), orderBy("order_id", "desc")));

        let orderDataArray = [];
        let total_order = 0;
        let total_cost = 0;
        let total_items = 0;
        let total_amount = 0;
        let prior_date = new Date().setDate(new Date().getDate() - 30);
        
        querySnapshot.forEach((doc) => {
            let cart_items_array = [];
            let cart_items1 = doc.data().cart_items.split("},{");
            cart_items1.forEach((element, index) => {
                if(cart_items1.length >= 2){
                    if(index === 0) {
                        let array_item = "{" + element.replace("[{", "") + "}";
                        cart_items_array.push(array_item);
                    } else if(index === cart_items1.length-1) {
                        let array_item = "{" + element.replace("}]", "") + "}";
                        cart_items_array.push(array_item);
                    } else {
                        let array_item = "{" + element + "}";
                        cart_items_array.push(array_item);
                    }
                } else {
                    let array_item = "{" + element.replace("[{", "").replace("}]", "") + "}";
                    cart_items_array.push(array_item);
                }
            })

            let stored_date = doc.data().order_time.seconds*1000;

            if(stored_date >= prior_date) {
                let order_items_array = [];
                total_order = total_order + 1;
                total_amount = total_amount + doc.data().order_total;
                cart_items_array.forEach((element) => {
                    order_items_array.push(JSON.parse(element));
                })

                let order_date = new Date(doc.data().order_time.seconds*1000);
                let pick_date = new Date(doc.data().pickup_date.seconds*1000);

                order_items_array.forEach((element1) => {
                    total_items = total_items + parseInt(element1.count);
                    total_cost = total_cost + (element1.price * element1.count);
                })

                let avgOrder_raw = total_amount/total_order;
                let avgOrder = avgOrder_raw.toFixed(2);
                let avgItem_raw = total_cost/total_items;
                let avgItem = avgItem_raw.toFixed(2);
                setAvgOrderValue(avgOrder);
                setAvgItemValue(avgItem);
                setTotalUnitsValue(total_items);
                setTotalOrdersValue(total_order);
                setTotalAmountValue(parseFloat(total_amount).toFixed(2));

                let orderDataObject = {
                    "order_items": order_items_array,
                    "comments": doc.data().comments,
                    "delivery_charge": doc.data().delivery_charge,
                    "delivery_within": doc.data().delivery_within,
                    "discount": doc.data().discount.toFixed(2),
                    "express_delivery": doc.data().express_delivery,
                    "name": doc.data().name,
                    "order_doc": doc.data().order_doc,
                    "order_id": doc.data().order_id,
                    "order_time": order_date,
                    "order_total": parseFloat(doc.data().order_total).toFixed(2),
                    "pickup_date": pick_date,
                    "status": doc.data().status,
                    "timeslot": doc.data().timeslot, 
                    "user_id": doc.data().user_id,
                    "store_pin": "",
                    "doc_id": doc.id
                }
                orderDataArray.push(orderDataObject);
            }
        })
        setDataArray(orderDataArray);
    }

    async function getCompletedOrderData() {
        const querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/completed_orders_id/completed_orders"), orderBy("order_id", "desc")));

        let orderDataArray = [];
        let total_order = 0;
        let total_cost = 0;
        let total_items = 0;
        let total_amount = 0;
        let prior_date = new Date().setDate(new Date().getDate() - 30);
        
        querySnapshot.forEach((doc) => {
            let cart_items_array = [];
            let cart_items1 = doc.data().cart_items.split("},{");
            cart_items1.forEach((element, index) => {
                if(cart_items1.length >= 2){
                    if(index === 0) {
                        let array_item = "{" + element.replace("[{", "") + "}";
                        cart_items_array.push(array_item);
                    } else if(index === cart_items1.length-1) {
                        let array_item = "{" + element.replace("}]", "") + "}";
                        cart_items_array.push(array_item);
                    } else {
                        let array_item = "{" + element + "}";
                        cart_items_array.push(array_item);
                    }
                } else {
                    let array_item = "{" + element.replace("[{", "").replace("}]", "") + "}";
                    cart_items_array.push(array_item);
                }
            })

            let stored_date = doc.data().order_time.seconds*1000;

            if(stored_date >= prior_date) {
                let order_items_array = [];
                total_order = total_order + 1;
                total_amount = total_amount + doc.data().order_total;
                cart_items_array.forEach((element) => {
                    order_items_array.push(JSON.parse(element));
                })

                let order_date = new Date(doc.data().order_time.seconds*1000);
                let pick_date = new Date(doc.data().pickup_date.seconds*1000);

                order_items_array.forEach((element1) => {
                    total_items = total_items + parseInt(element1.count);
                    total_cost = total_cost + (element1.price * element1.count);
                })

                let avgOrder_raw = total_amount/total_order;
                let avgOrder = avgOrder_raw.toFixed(2);
                let avgItem_raw = total_cost/total_items;
                let avgItem = avgItem_raw.toFixed(2);
                setAvgOrderValue(avgOrder);
                setAvgItemValue(avgItem);
                setTotalUnitsValue(total_items);
                setTotalOrdersValue(total_order);
                setTotalAmountValue(parseFloat(total_amount).toFixed(2));

                let orderDataObject = {
                    "order_items": order_items_array,
                    "comments": doc.data().comments,
                    "delivery_charge": doc.data().delivery_charge,
                    "delivery_within": doc.data().delivery_within,
                    "discount": doc.data().discount.toFixed(2),
                    "express_delivery": doc.data().express_delivery,
                    "name": doc.data().name,
                    "order_doc": doc.data().order_doc,
                    "order_id": doc.data().order_id,
                    "order_time": order_date,
                    "order_total": parseFloat(doc.data().order_total).toFixed(2),
                    "pickup_date": pick_date,
                    "status": doc.data().status,
                    "timeslot": doc.data().timeslot, 
                    "user_id": doc.data().user_id,
                    "store_pin": "",
                    "doc_id": doc.id
                }
                orderDataArray.push(orderDataObject);
            }
        })
        setDataArray(orderDataArray);
    }

    const getOrderDetails = (event) => {
        event.nativeEvent.path[1].parentNode.childNodes[0].childNodes[2].style.display = "block";
        event.nativeEvent.path[1].childNodes[0].style.display = "none";
        event.nativeEvent.path[1].childNodes[1].style.display = "block";
    }

    const closeOrderDetails = (event) => {
        event.nativeEvent.path[1].parentNode.childNodes[0].childNodes[2].style.display = "none";
        event.nativeEvent.path[1].childNodes[0].style.display = "block";
        event.nativeEvent.path[1].childNodes[1].style.display = "none";
    }

    const analyticsOrderSelection = () => {
        let selectedValue = document.querySelector("#analyticsApplicationOrderSelect").value;

        if(selectedValue === "upcoming_orders") {
            getOrderData();
        } else if(selectedValue === "accepted_orders") {
            getAcceptedOrderData();
        } else if(selectedValue === "warehouse_orders") {
            getWarehouseOrderData();
        } else if(selectedValue === "completed_orders") {
            getCompletedOrderData();
        }
    }

    const applicationBilledOrderSelect = async() => {
        let selectedValue = document.querySelector("#applicationBilledOrderSelect").value;

        if(selectedValue === "order_time") {
            document.querySelector(".dateApplicationFilterDiv").style.display = "flex";
            document.querySelector(".pinCodeFilterDiv").style.display = "none"
        } else if(selectedValue === "pinCode") {
            document.querySelector(".dateApplicationFilterDiv").style.display = "none";
            document.querySelector(".pinCodeFilterDiv").style.display = "flex"
            let snapshot = await getDocs(collection(firestore, "pin"));

            let pincodeArray = [];
            snapshot.forEach((element) => {
                pincodeArray.push(element.id);
            });
            setPinCodes([...pincodeArray]);
        } else {
            document.querySelector(".dateApplicationFilterDiv").style.display = "none";
            document.querySelector(".pinCodeFilterDiv").style.display = "none"
        }
    }

    const dateApplicationFilterSelect = async(event) => {
        event.preventDefault();

        let querySnapshot;
        let selectedValue = document.querySelector("#analyticsApplicationOrderSelect").value;

        if(selectedValue === "upcoming_orders") {
            querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/upcoming_orders_id/upcoming_orders"), orderBy("order_id", "desc")));
        } else if(selectedValue === "accepted_orders") {
            querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/accepted_orders_id/accepted_orders"), orderBy("order_id", "desc")));
        } else if(selectedValue === "warehouse_orders") {
            querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/in_warehouse_id/in_warehouse"), orderBy("order_id", "desc")));
        } else if(selectedValue === "completed_orders") {
            querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/completed_orders_id/completed_orders"), orderBy("order_id", "desc")));
        }

        // const querySnapshot =  await getDocs(query(collection(firestore, "/billing_orders/orders/accepted_orders"), orderBy("order_id", "desc")));
        let orderDataArray = [];
        let total_order = 0;
        let total_cost = 0;
        let total_items = 0;
        let total_amount = 0;
        let cart_items_array = [];

        let date = "";
        let fromDate = new Date(document.querySelector("#dateApplicationFrom").value).setHours(0, 0, 0,0);
        let toDate = new Date(document.querySelector("#dateApplicationTo").value).setHours(0, 0, 0,0);

        querySnapshot.forEach((doc) => {
            let cart_items1 = doc.data().cart_items.split("},{");
            cart_items1.forEach((element, index) => {
                if(cart_items1.length >= 2){
                    if(index === 0) {
                        let array_item = "{" + element.replace("[{", "") + "}";
                        cart_items_array.push(array_item);
                    } else if(index === cart_items1.length-1) {
                        let array_item = "{" + element.replace("}]", "") + "}";
                        cart_items_array.push(array_item);
                    } else {
                        let array_item = "{" + element + "}";
                        cart_items_array.push(array_item);
                    }
                } else {
                    let array_item = "{" + element.replace("[{", "").replace("}]", "") + "}";
                    cart_items_array.push(array_item);
                }
            })

            date = doc.data().order_time.seconds*1000;
            if(date >= fromDate && date <= toDate) {
                let order_items_array = [];
                total_order = total_order + 1;
                total_amount = total_amount + doc.data().order_total;
                cart_items_array.forEach((element) => {
                    order_items_array.push(JSON.parse(element));
                })

                let order_date = new Date(doc.data().order_time.seconds*1000);
                let pick_date = new Date(doc.data().pickup_date.seconds*1000);

                order_items_array.forEach((element1) => {
                    total_items = total_items + parseInt(element1.count);
                    total_cost = total_cost + (element1.price * element1.count);
                })

                let avgOrder_raw = total_amount/total_order;
                let avgOrder = avgOrder_raw.toFixed(2);
                let avgItem_raw = total_cost/total_items;
                let avgItem = avgItem_raw.toFixed(2);
                setAvgOrderValue(avgOrder);
                setAvgItemValue(avgItem);
                setTotalUnitsValue(total_items);
                setTotalOrdersValue(total_order);
                setTotalAmountValue(parseFloat(total_amount).toFixed(2));

                let orderDataObject = {
                    "order_items": order_items_array,
                    "comments": doc.data().comments,
                    "delivery_charge": doc.data().delivery_charge,
                    "delivery_within": doc.data().delivery_within,
                    "discount": doc.data().discount.toFixed(2),
                    "express_delivery": doc.data().express_delivery,
                    "name": doc.data().name,
                    "order_doc": doc.data().order_doc,
                    "order_id": doc.data().order_id,
                    "order_time": order_date,
                    "order_total": parseFloat(doc.data().order_total).toFixed(2),
                    "pickup_date": pick_date,
                    "status": doc.data().status,
                    "timeslot": doc.data().timeslot, 
                    "user_id": doc.data().user_id,
                    "store_pin": "",
                    "doc_id": doc.id
                }
                orderDataArray.push(orderDataObject);
            }
        })
        setDataArray([...orderDataArray]);
    }

    async function pinCodesSelect(event) {
        event.preventDefault();
        let pincodesArray = []
        document.querySelectorAll("#pinCode option").forEach((element) => {
            if(element.selected === true) {
                pincodesArray.push(element.value);
            }
        });

        let querySnapshot;
        let selectedValue = document.querySelector("#analyticsApplicationOrderSelect").value;

        if(selectedValue === "upcoming_orders") {
            querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/upcoming_orders_id/upcoming_orders"), where("pincode", "in", pincodesArray)));
        } else if(selectedValue === "accepted_orders") {
            querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/accepted_orders_id/accepted_orders"), where("pincode", "in", pincodesArray)));
        } else if(selectedValue === "warehouse_orders") {
            querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/in_warehouse_id/in_warehouse"), where("pincode", "in", pincodesArray)));
        } else if(selectedValue === "completed_orders") {
            querySnapshot =  await getDocs(query(collection(firestore, "/orders_temp/completed_orders_id/completed_orders"), where("pincode", "in", pincodesArray)));
        }

        let orderDataArray = [];
        let total_order = 0;
        let total_cost = 0;
        let total_items = 0;
        let total_amount = 0;
        let cart_items_array = [];

        querySnapshot.forEach((doc) => {
            let cart_items1 = doc.data().cart_items.split("},{");
            cart_items1.forEach((element, index) => {
                if(cart_items1.length >= 2){
                    if(index === 0) {
                        let array_item = "{" + element.replace("[{", "") + "}";
                        cart_items_array.push(array_item);
                    } else if(index === cart_items1.length-1) {
                        let array_item = "{" + element.replace("}]", "") + "}";
                        cart_items_array.push(array_item);
                    } else {
                        let array_item = "{" + element + "}";
                        cart_items_array.push(array_item);
                    }
                } else {
                    let array_item = "{" + element.replace("[{", "").replace("}]", "") + "}";
                    cart_items_array.push(array_item);
                }
            })

            let order_items_array = [];
            total_order = total_order + 1;
            total_amount = total_amount + doc.data().order_total;
            cart_items_array.forEach((element) => {
                order_items_array.push(JSON.parse(element));
            })

            let order_date = new Date(doc.data().order_time.seconds*1000);
            let pick_date = new Date(doc.data().pickup_date.seconds*1000);

            order_items_array.forEach((element1) => {
                total_items = total_items + parseInt(element1.count);
                total_cost = total_cost + (element1.price * element1.count);
            })

            let avgOrder_raw = total_amount/total_order;
            let avgOrder = avgOrder_raw.toFixed(2);
            let avgItem_raw = total_cost/total_items;
            let avgItem = avgItem_raw.toFixed(2);
            setAvgOrderValue(avgOrder);
            setAvgItemValue(avgItem);
            setTotalUnitsValue(total_items);
            setTotalOrdersValue(total_order);
            setTotalAmountValue(parseFloat(total_amount).toFixed(2));

            let orderDataObject = {
                "order_items": order_items_array,
                "comments": doc.data().comments,
                "delivery_charge": doc.data().delivery_charge,
                "delivery_within": doc.data().delivery_within,
                "discount": doc.data().discount.toFixed(2),
                "express_delivery": doc.data().express_delivery,
                "name": doc.data().name,
                "order_doc": doc.data().order_doc,
                "order_id": doc.data().order_id,
                "order_time": order_date,
                "order_total": parseFloat(doc.data().order_total).toFixed(2),
                "pickup_date": pick_date,
                "status": doc.data().status,
                "timeslot": doc.data().timeslot, 
                "user_id": doc.data().user_id,
                "store_pin": "",
                "doc_id": doc.id
            }
            orderDataArray.push(orderDataObject);
        })
        setDataArray([...orderDataArray]);
    }

    async function deleteOrderDetails(user_id, doc_id, order_id, user_doc_id) {
        let confirmDelete = prompt("Enter "+order_id+" to confirm the delete of the order.");

        if(confirmDelete === order_id) {
            let selectedValue = document.querySelector("#analyticsApplicationOrderSelect").value;

            if(selectedValue === "upcoming_orders") {
                await deleteDoc(doc(firestore, "/orders_temp/upcoming_orders_id/upcoming_orders", doc_id));
                getOrderData();
            } else if(selectedValue === "accepted_orders") {
                await deleteDoc(doc(firestore, "/orders_temp/accepted_orders_id/accepted_orders", doc_id));
                getAcceptedOrderData();
            } else if(selectedValue === "warehouse_orders") {
                await deleteDoc(doc(firestore, "/orders_temp/in_warehouse_id/in_warehouse", doc_id));
                getWarehouseOrderData();
            } else if(selectedValue === "completed_orders") {
                await deleteDoc(doc(firestore, "/orders_temp/completed_orders_id/completed_orders", doc_id));
                getCompletedOrderData();
            }
            await deleteDoc(doc(firestore, "/users/" + user_id + "/user_orders", user_doc_id));
        } else {
            alert("You Entered the wrong id.");
            deleteOrderDetails(user_id, doc_id, order_id, user_doc_id);
        }
    }

    useEffect(() => {
        document.querySelector(".left-analytics").classList.add("active");
        getOrderData();
    }, [])

    return (
        <div className='application-analytics-container'>
            <div className='order-analytics-search-container'>
                <select id='analyticsApplicationOrderSelect' onChange={analyticsOrderSelection}>
                    <option value="upcoming_orders">Upcoming Orders</option>
                    <option value="accepted_orders">Accepted Orders</option>
                    <option value="warehouse_orders">In Warehouse Orders</option>
                    <option value="completed_orders">Completed Orders</option>
                </select>
                <select id='applicationBilledOrderSelect' onChange={applicationBilledOrderSelect}>
                    <option value="">Select Filter</option>
                    <option value="order_time">Order Time</option>
                    <option value="pinCode">PinCode</option>
                </select>
                <form className='dateApplicationFilterDiv'>
                    <label for="dateFrom">From: </label>
                    <input type="date" name="" id='dateApplicationFrom' required />
                    <label for="dateTo">To:</label>
                    <input type="date" name="" id='dateApplicationTo' required />
                    <button onClick={dateApplicationFilterSelect} type="submit" className='analytics-button active'>Apply</button>
                </form>
                <form className='pinCodeFilterDiv'>
                    <label for="pinCode">PinCodes</label>
                    <select name="" id='pinCode' required multiple>
                        {pinCodes.map((element) => (
                            <option value={element}>{element}</option>
                        ))}
                    </select>
                    <button onClick={pinCodesSelect} type="submit" className='analytics-button active'>Apply</button>
                </form>
            </div>
            <div className='order-analytics-display-container'>
                {dataArray.map((element, index) => (
                    <div key={index} className='order-analytics-heads'>
                        <div className="order-analytics-heads-main">
                            <div className="order-analytics-heads-main-heads">
                                <div className='heads order-name'>
                                    Name
                                </div>
                                <div className='heads order-id'>
                                    Order ID
                                </div>
                                <div className='heads order-date'>
                                    Order Date
                                </div>
                                <div className='heads order-total'>
                                    Order Total
                                </div>
                            </div>
                            <div className='order-analytics-heads-main-content'>
                                <div className='heads order-name'>
                                    {element.name}
                                </div>
                                <div className='heads order-id'>
                                    {element.order_id}
                                </div>
                                <div className='heads order-date'>
                                    {element.order_time.toString()}
                                </div>
                                <div className='heads order-total'>
                                    Rs. {element.order_total}
                                </div>
                            </div>
                            <div className="order-details-container">
                                <div>
                                    <div>
                                        <div className="order-user-container">
                                            <div className="heads order-user-id-head">
                                                User ID : 
                                            </div>
                                            <div className='heads order-user-id'>
                                                {element.user_id}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads order-doc-id-head">
                                                Order Doc ID : 
                                            </div>
                                            <div className='heads order-doc-id'>
                                                {element.order_doc}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads order-status-head">
                                                Order Status : 
                                            </div>
                                            <div className='heads order-status'>
                                                {element.status}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads pickup-head">
                                                PickUp Date : 
                                            </div>
                                            <div className='heads pickup'>
                                                {element.pickup_date.toString()}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads delivery-head">
                                                Delivery Charge : 
                                            </div>
                                            <div className='heads delivery'>
                                                {element.delivery_charge}
                                            </div>
                                        </div>
                                    </div>
                                    <div>    
                                        <div className="order-user-container">
                                            <div className="heads delivery-within-head">
                                                Delivery Within : 
                                            </div>
                                            <div className='heads delivery-within'>
                                                {element.delivery_within}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads express-delivery-head">
                                                Express Delivery Charge : 
                                            </div>
                                            <div className='heads express-delivery'>
                                                {element.express_delivery}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads discount-head">
                                                Discount : 
                                            </div>
                                            <div className='heads discount'>
                                                {element.discount}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads time-slot-head">
                                                Time Slot : 
                                            </div>
                                            <div className='heads time-slot'>
                                                {element.timeslot}
                                            </div>
                                        </div>
                                        <div className="order-user-container">
                                            <div className="heads store-pin-head">
                                                Store Pin : 
                                            </div>
                                            <div className='heads store-pin'>
                                                {element.store_pin}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-user-container">
                                    <div className="heads order-items-head">
                                        Order Items : 
                                    </div>
                                </div>
                                <div className='cart-items'>
                                    <div className='order-user-container'>
                                        <div className='heads pricing-service'>
                                            Service
                                        </div>
                                        <div className='heads pricing-category'>
                                            Category
                                        </div>
                                        <div className='heads pricing-item'>
                                            Quantity
                                        </div>
                                        <div className='heads pricing-quantity'>
                                            Price
                                        </div>
                                        <div className='heads pricing-cost'>
                                            Cost
                                        </div>
                                    </div>
                                    <div className="order-user-container">
                                        {element.order_items.map((element1, index) => (
                                            <div key={index}>
                                                <div className='heads pricing-service'>
                                                    {element1.service}
                                                </div>
                                                <div className='heads pricing-category'>
                                                    {element1.category}
                                                </div>
                                                <div className='heads pricing-item'>
                                                    {element1.count} Qty.
                                                </div>
                                                <div className='heads pricing-quantity'>
                                                    Rs. {element1.price}
                                                    {element1.Suffix}
                                                </div>
                                                <div className='heads pricing-cost'>
                                                    Rs. {element1.price * element1.count}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='order-details-button-container'>
                            <button className='heads order-details open' onClick={getOrderDetails}>
                                Click Here
                            </button>
                            <button className='heads order-details close' onClick={closeOrderDetails}>
                                Close
                            </button>
                            <button className='heads order-details delete' onClick={event => deleteOrderDetails(element.user_id, element.doc_id, element.order_id, element.order_doc)}>
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="order-analysis-container">
                <div className="order-analysis-heads">
                    <div className='heads order-avg-value order-total-head'>
                        Avg Order Value
                    </div>
                    <div className='heads order-item-value'>
                        Avg Item Value
                    </div>
                    <div className='heads order-units'>
                        Total Units
                    </div>
                    <div className='heads order-total-head'>
                        Total Orders
                    </div>
                    <div className='heads order-total-amount'>
                        Total Amount
                    </div>
                </div>
                <div className='order-analysis-main-content'>
                    <div className='heads order-name'>
                        Rs. {avgOrderValue}
                    </div>
                    <div className='heads order-id'>
                        Rs. {avgItemValue}
                    </div>
                    <div className='heads order-date'>
                        {totalUnitsValue}
                    </div>
                    <div className='heads order-total'>
                        {totalOrdersValue}
                    </div>
                    <div className='heads order-total'>
                        Rs. {totalAmountValue}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationAnalytics