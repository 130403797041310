import React, { useState, useEffect } from 'react';
import "./NewOrder.css";
import emailjs from '@emailjs/browser';
import { firestore, storage } from '../../firebase/firebaseConfig';
import { collection, getDocs, addDoc, updateDoc, doc, setDoc } from '@firebase/firestore';
import jsPDFInvoiceTemplate, { OutputType } from 'jspdf-invoice-template';
import { ref, uploadBytes, deleteObject } from "firebase/storage";

function NewOrder() {
    let [userId, setUserId] = useState("");
    let [phoneNumber, setPhone] = useState("");
    let [address, setAddress] = useState("");
    let [email, setEmail] = useState("");

    let [displayCategory, setDisplayCategory] = useState([]);
    let [displayItems, setDisplayItems] = useState([]);
    let [billedItems, setBilledItems] = useState([]);
    let [previousItems, setPreviousItems] = useState([]);
    let [total, setTotal] = useState(0);
    let [data, setData] = useState([]);

    let [serviceInput, setService] = useState("");
    let [serviceIndex, setServiceIndex] = useState("");
    let [suffix, setSuffix] = useState("");

    let services = ["wash_and_fold", "wash_fold_iron", "dry_clean", "others"];
    let displayService = ["Wash And Fold", "Wash And Iron", "Dry Clean", "Others"];

    let categories = [
        ["wearables", "non_wearables"],
        ["wearables", "non_wearables"],
        ["men", "women", "kids", "others"],
        ["other_items"]
    ]

    let categories_display = [
        ["Wearables", "Non Wearables"],
        ["Wearables", "Non Wearables"],
        ["Men", "Women", "Kids", "Others"],
        ["Other Items"]
    ];

    const numberChange = () => {
        document.querySelector(".name").value = "";
        document.querySelector(".email").value = "";
        document.querySelector(".address").value = "";
        document.querySelector(".user-exist-no").style.display = "none";
        document.querySelector(".user-exist").style.display = "none";
        document.querySelector(".searchButton").removeAttribute("disabled");
        document.querySelector(".setBillButton").setAttribute("disabled", "");
        document.querySelector(".setUserButton").setAttribute("disabled", "");
    }

    async function getUserData(event) {
        event.preventDefault();
        let userStoreObject = {}
        let mobile = "";

        const querySnapshot =  await getDocs(collection(firestore, "/billing_users/"));
        let mobile_input = document.querySelector(".phone").value;

        if(mobile_input.includes("+91")) {
            mobile = "" + mobile_input;
        } else {
            mobile = "+91" + mobile_input;
        }

        querySnapshot.forEach((doc) => {
            if(doc.data().phone === mobile) {
                userStoreObject = {
                    "Name": doc.data().name,
                    "Email": doc.data().email,
                    "Address": doc.data().address,
                    "Phone": doc.data().phone
                }
                setUserId(doc.id);
                setPhone(doc.data().phone);
                setAddress(doc.data().address);
                setEmail(doc.data().email);
            }
        })

        if(userStoreObject.Phone) {
            document.querySelector(".user-exist").style.display = "block";
            document.querySelector(".user-exist-no").style.display = "none";
            document.querySelector(".name").value = userStoreObject.Name;
            document.querySelector(".email").value = userStoreObject.Email;
            document.querySelector(".address").value = userStoreObject.Address;
            document.querySelector(".setBillButton").removeAttribute("disabled");
        } 
        else if (!userStoreObject.Phone) {
            document.querySelector(".user-exist-no").style.display = "block";
            document.querySelector(".user-exist").style.display = "none";
            document.querySelector(".setUserButton").removeAttribute("disabled");
        }

        document.querySelector(".searchButton").setAttribute("disabled", "")

        setTimeout(function(){
            document.querySelector(".user-exist-no").style.display = "none";
            document.querySelector(".user-exist").style.display = "none";
        }, 4000);
    }

    const serviceSelect = () => {
        document.querySelector("#category-select").removeAttribute("disabled");
        document.querySelector("#category-select").value = "";
        document.querySelector("#item-select").value = "";
        document.querySelector(".quantity-input").value = "";
        document.querySelector(".price-input").value = "";
        document.querySelector("#item-select").setAttribute("disabled", "");
        document.querySelector(".quantity-input").setAttribute("disabled", "");
        document.querySelector(".price-input").setAttribute("disabled", "");
        document.querySelector(".pieces-input").setAttribute("disabled", "");
        let serviceIndex = document.querySelector("#service-select").value;
        setServiceIndex(serviceIndex);
        setDisplayCategory(categories_display[serviceIndex]);
        setService(services[serviceIndex]);
    }

    const categorySelect = () => {
        document.querySelector("#item-select").removeAttribute("disabled");
        document.querySelector("#item-select").value = "";
        document.querySelector(".quantity-input").value = "";
        document.querySelector(".price-input").value = "";
        document.querySelector(".quantity-input").setAttribute("disabled", "");
        document.querySelector(".price-input").setAttribute("disabled", "");
        document.querySelector(".pieces-input").setAttribute("disabled", "");
        let categoryIndex = document.querySelector("#category-select").value;
        let categoryInput = categories[serviceIndex][categoryIndex];
        getRateListItems(serviceInput, categoryInput);
    }

    const itemSelect = () => {
        document.querySelector(".quantity-input").removeAttribute("disabled");
        document.querySelector(".price-input").removeAttribute("disabled");
        document.querySelector(".pieces-input").removeAttribute("disabled");
        document.querySelector(".discount-input").removeAttribute("disabled");
        document.querySelector(".exp-del-input").removeAttribute("disabled");
        document.querySelector(".del-input").removeAttribute("disabled");
        document.querySelector(".quantity-input").value = "1";
        let itemIndex = document.querySelector("#item-select").value;
        let priceValue = data[itemIndex].Price;
        document.querySelector(".price-input").value = priceValue;
        let suffixValue = data[itemIndex].Suffix;
        setSuffix(suffixValue);
    }

    const addItem = (event) => {
        event.preventDefault();
        let billedArray = [];
        previousItems.forEach((element) => {
            billedArray.push(element);
        })

        let service = document.querySelector("#service-select");
        let category = document.querySelector("#category-select");
        let item = document.querySelector("#item-select");
        let serviceIndex = parseInt(document.querySelector("#service-select").value)+1;
        let categoryIndex = parseInt(document.querySelector("#category-select").value)+1;
        let itemIndex = parseInt(document.querySelector("#item-select").value)+1;
        let serviceValue = service.options[serviceIndex].text;
        let categoryValue = category.options[categoryIndex].text;
        let itemValue = item.options[itemIndex].text;
        let quantityValue = document.querySelector(".quantity-input").value;
        let priceValue = document.querySelector(".price-input").value;
        let suffixValue = suffix;
        let piece = document.querySelector(".pieces-input").value;
        let pieces = "";
        if(piece === "" || piece === " ") {
            pieces = "";
        } else {
            pieces = piece + " pcs.";
        }

        let quantityInt = parseFloat(quantityValue).toFixed(2);
        let priceInt = parseInt(priceValue);
        let cost = quantityInt * priceInt;
        let totalCost = parseFloat(total) + cost;

        let billedObject = {
            "Service": serviceValue,
            "Category": categoryValue,
            "Item": itemValue,
            "Quantity": quantityInt,
            "Price": priceValue,
            "Suffix": suffixValue,
            "Pieces": pieces,
            "Cost": cost.toFixed(2)
        };

        billedArray.push(billedObject);

        setPreviousItems(billedArray);
        setBilledItems(billedArray);
        setTotal(totalCost.toFixed(2));

        document.querySelector("#service-select").value = "";
        document.querySelector("#category-select").value = "";
        document.querySelector("#item-select").value = "";
        document.querySelector(".quantity-input").value = "";
        document.querySelector(".price-input").value = "";
        document.querySelector(".pieces-input").value = "";
        document.querySelector("#category-select").setAttribute("disabled", "");
        document.querySelector("#item-select").setAttribute("disabled", "");
        document.querySelector(".quantity-input").setAttribute("disabled", "");
        document.querySelector(".price-input").setAttribute("disabled", "");
        document.querySelector(".pieces-input").setAttribute("disabled", "");
    }

    const removeItem = () => {
        let removeArray = [];

        previousItems.forEach((element) => {
            removeArray.push(element);
        })

        let remove = document.querySelectorAll(".remove-item");

        for(let j = 0; j<remove.length; j++) {
            remove[j].addEventListener('click', () => {
                let totalCost = parseFloat(total) - removeArray[j].Cost;
                removeArray.splice(j, 1);
                setTotal(totalCost);
            })
        }

        setPreviousItems(removeArray);
        setBilledItems(removeArray);
    }

    async function getRateListItems(service, category) {
        const querySnapshot =  await getDocs(collection(firestore, "/rate_list/"+service+"/"+category));
        let DataArray = [];
        let DataObject = {};
        let items = [];
        querySnapshot.forEach((doc) => {
            let suffix = doc.data()["Suffix"];
            items.push(doc.data()["category"]);

            if(suffix === '#') {
                DataObject = {
                    "Category": doc.data()["category"],
                    "Price": doc.data()["price"]
                }
            } else {
                DataObject = {
                    "Suffix": doc.data()["Suffix"],
                    "Category": doc.data()["category"],
                    "Price": doc.data()["price"]
                }
            }

            DataArray.push(DataObject);
        
        })
        setDisplayItems(items);
        setData(DataArray);
    }

    async function setUserData(event) {
        event.preventDefault();
        let mobile = "";
        let mobile_input = document.querySelector(".phone").value;

        if(mobile_input.includes("+91")) {
            mobile = "" + mobile_input;
        } else {
            mobile = "+91" + mobile_input;
        }

        setPhone(mobile);

        let user_name = document.querySelector(".name").value;
        let user_email = document.querySelector(".email").value;
        let user_address = document.querySelector(".address").value;
        
        if(user_name === "" || user_address === "") {
            alert("Kindly Enter All the details in the empty fields.");
        }

        if(user_email === "") {
            user_email = "N/A";
        }

        if(user_name !== "" && user_address !== "") {
            let userDataObj = {
                "name": user_name,
                "email": user_email,
                "phone": mobile,
                "address": user_address,
                "pincode": "560100"
            }

            setAddress(user_address);
            setEmail(user_email);
    
            let new_user_doc = await addDoc(collection(firestore, "billing_users"), userDataObj);
            
            document.querySelector(".setBillButton").removeAttribute("disabled");
            document.querySelector(".setUserButton").setAttribute("disabled", "");
            setUserId(new_user_doc.id);
        }
    }

    async function setBillData(event) {
        event.preventDefault();
        if(billedItems.length !== 0) {
            const querySnapshot =  await getDocs(collection(firestore, "/order_id/"));
            let prefix, count;
            let full_count = "000000";
            let express_delivery = 0;
            let delivery = 0;

            querySnapshot.forEach((doc) => {
                prefix = doc.data()["prefix"];
                count = doc.data()["order_count"];
            })

            let serverBilledArray = [];
            billedItems.forEach((element) => {
                serverBilledArray.push(JSON.stringify(element));
            })

            let order_items = serverBilledArray;
            let discount = parseInt(document.querySelector(".discount-input").value);
            let del_check = document.querySelector(".del-input").checked;
            if(del_check) {
                if(parseFloat(total) < 250) {
                    delivery = 50;
                }
            }
            let express_check = document.querySelector(".exp-del-input").checked;
            if(express_check) {
                let total_before_express = parseFloat(total);
                express_delivery = total_before_express;
            }
            let fullCountLength = 6 - count.toString().length;
            let order_id = prefix + full_count.substr(0, fullCountLength) + count.toString();
            let current_date = new Date();
            let order_total = parseFloat(total) + delivery + express_delivery - discount;

            let user_id = userId;
            let user_name = document.querySelector(".name").value;

            document.querySelector(".phone").value = "";
            document.querySelector(".name").value = "";
            document.querySelector(".email").value = "";
            document.querySelector(".address").value = "";
            setBilledItems([]);
            setPreviousItems([]);
            setTotal(0);
            document.querySelector("#service-select").value = "";
            document.querySelector("#category-select").value = "";
            document.querySelector("#item-select").value = "";
            document.querySelector(".quantity-input").value = "";
            document.querySelector(".price-input").value = "";
            document.querySelector(".pieces-input").value = "";
            document.querySelector(".discount-input").value = "0";
            document.querySelector(".del-input").checked = false;
            document.querySelector(".exp-del-input").checked = false;
            document.querySelector("#category-select").setAttribute("disabled", "");
            document.querySelector("#item-select").setAttribute("disabled", "");
            document.querySelector(".quantity-input").setAttribute("disabled", "");
            document.querySelector(".price-input").setAttribute("disabled", "");
            document.querySelector(".discount-input").setAttribute("disabled", "");
            document.querySelector(".pieces-input").setAttribute("disabled", "");
            document.querySelector(".del-input").setAttribute("disabled", "");
            document.querySelector(".exp-del-input").setAttribute("disabled", "");
            document.querySelector(".setBillButton").setAttribute("disabled", "");
            document.querySelector(".setUserButton").setAttribute("disabled", "");
            document.querySelector(".searchButton").removeAttribute("disabled");
            
            document.querySelector(".bill-created").style.display = "block";

            setTimeout(function(){
                document.querySelector(".bill-created").style.display = "none";
            }, 3000);
            
            let userOrderObj = {
                "order_items": order_items,
                "discount": discount,
                "delivery": delivery,
                "express_delivery": express_delivery,
                "order_id": order_id,
                "order_time": current_date,
                "order_total": order_total,
                "order_status": "accepted",
                "store_pin": 103,
                "user_id": user_id
            }

            let order_new = await addDoc(collection(firestore, "billing_users/"+user_id+"/user_order"), userOrderObj);

            let userExistingOrderObj = {
                "order_items": order_items,
                "discount": discount,
                "delivery": delivery,
                "express_delivery": express_delivery,
                "order_id": order_id,
                "order_time": current_date,
                "order_total": order_total,
                "order_status": "accepted",
                "store_pin": 103,
                "order_doc_id": order_new.id,
                "user_name": user_name,
                "user_id": user_id
            }
            
            await setDoc(doc(firestore, "billing_orders/orders/accepted_orders", order_new.id), userExistingOrderObj);

            let orderDocRef = doc(firestore, "order_id", "order_id_doc")

            let new_count = count + 1;

            await updateDoc(orderDocRef, {
                order_count: new_count
            });

            let props = {
                outputType: OutputType.DataUriString,
                returnJsPDFDocObject: true,
                fileName: "LaundryBuddy "+order_id,
                orientationLandscape: false,
                compress: true,
                logo: {
                    src: require("../../images/logo.png"),
                    type: 'PNG', //optional, when src= data:uri (nodejs case)
                    width: 26.66, //aspect ratio = width/height
                    height: 26.66,
                    margin: {
                        top: 0, //negative or positive num, from the current position
                        left: 0 //negative or positive num, from the current position
                    }
                },
                business: {
                    name: "Laundry Buddy",
                    address: "Electronic City Phase-1, Bengaluru, Karnataka 560100",
                    phone: "7022578103",
                    email: "contact@laundrybuddy.in",
                    website: "https://laundrybuddy.in",
                },
                contact: {
                    label: "Invoice issued for:",
                    name: user_name,
                    address: address,
                    phone: phoneNumber,
                    email: email,
                },
                invoice: {
                    label: "OrderID: ",
                    num: order_id,
                    invGenDate: "Invoice Date:" + current_date.toString(),
                    headerBorder: true,
                    tableBodyBorder: true,
                    header: [
                    {
                        title: "#", 
                        style: { 
                        width: 10 
                        } 
                    }, 
                    { 
                        title: "Service",
                        style: {
                        width: 40
                        } 
                    }, 
                    { 
                        title: "Category",
                        style: {
                        width: 40
                        } 
                    }, 
                    { title: "Item"},
                    { title: "Quantity"},
                    { title: "Price"},
                    { title: "Pieces"},
                    { title: "Total Cost"}
                    ],
                    table: Array.from(billedItems, (item, index)=>([
                        index + 1,
                        item.Service,
                        item.Category,
                        item.Item,
                        item.Quantity,
                        item.Price,
                        item.Pieces,
                        item.Cost
                    ])),
                    additionalRows: [{
                        col1: 'SubTotal:',
                        col2: total.toString(),
                        style: {
                            fontSize: 10
                        },
                    },
                    {
                        col1: '+ Delivery Charge:',
                        col2: delivery.toString(),
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    },
                    {
                        col1: '+ Express Delivery Charge:',
                        col2: express_delivery.toString(),
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    },
                    {
                        col1: '- Discount:',
                        col2: discount.toString(),
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    },
                    {
                        col1: 'Total:',
                        col2: order_total.toString(),
                        style: {
                            fontSize: 14 //optional, default 12
                        }
                    }],
                },
                footer: {
                    text: "Thank You For Choosing Us.",
                },
                pageEnable: false,
                pageLabel: "",
            };

            let jsPDF = jsPDFInvoiceTemplate( props );
            let dataUriString = jsPDF.dataUriString;
            if(email !== "N/A") {
                emailjs.send("service_mjttko9", "template_wocokr4", {
                    content: dataUriString,
                    email: email,
                    order_id: order_id
                }, "gkM2SdSnL3a-1yUti");
            }

            let props_1 = {
                outputType: OutputType.Blob,
                returnJsPDFDocObject: true,
                fileName: "LaundryBuddy "+order_id,
                orientationLandscape: false,
                compress: true,
                logo: {
                    src: require("../../images/logo.png"),
                    type: 'PNG', //optional, when src= data:uri (nodejs case)
                    width: 26.66, //aspect ratio = width/height
                    height: 26.66,
                    margin: {
                        top: 0, //negative or positive num, from the current position
                        left: 0 //negative or positive num, from the current position
                    }
                },
                business: {
                    name: "Laundry Buddy",
                    address: "Electronic City Phase-1, Bengaluru, Karnataka 560100",
                    phone: "7022578103",
                    email: "contact@laundrybuddy.in",
                    website: "https://laundrybuddy.in",
                },
                contact: {
                    label: "Invoice issued for:",
                    name: user_name,
                    address: address,
                    phone: phoneNumber,
                    email: email,
                },
                invoice: {
                    label: "OrderID: ",
                    num: order_id,
                    invGenDate: "Invoice Date:" + current_date.toString(),
                    headerBorder: true,
                    tableBodyBorder: true,
                    header: [
                    {
                        title: "#", 
                        style: { 
                        width: 10 
                        } 
                    }, 
                    { 
                        title: "Service",
                        style: {
                        width: 40
                        } 
                    }, 
                    { 
                        title: "Category",
                        style: {
                        width: 40
                        } 
                    }, 
                    { title: "Item"},
                    { title: "Quantity"},
                    { title: "Price"},
                    { title: "Pieces"},
                    { title: "Total Cost"}
                    ],
                    table: Array.from(billedItems, (item, index)=>([
                        index + 1,
                        item.Service,
                        item.Category,
                        item.Item,
                        item.Quantity,
                        item.Price,
                        item.Pieces,
                        item.Cost
                    ])),
                    additionalRows: [{
                        col1: 'SubTotal:',
                        col2: total.toString(),
                        style: {
                            fontSize: 10
                        },
                    },
                    {
                        col1: '+ Delivery Charge:',
                        col2: delivery.toString(),
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    },
                    {
                        col1: '+ Express Delivery Charge:',
                        col2: express_delivery.toString(),
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    },
                    {
                        col1: '- Discount:',
                        col2: discount.toString(),
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    },
                    {
                        col1: 'Total:',
                        col2: order_total.toString(),
                        style: {
                            fontSize: 14 //optional, default 12
                        }
                    }],
                },
                footer: {
                    text: "Thank You For Choosing Us.",
                },
                pageEnable: false,
                pageLabel: "",
            };

            let jsPDF_1 = jsPDFInvoiceTemplate( props_1 );
            let pdf_blob = jsPDF_1.blob;
            const storageRef = ref(storage, 'order_invoice/'+order_id);

            uploadBytes(storageRef, pdf_blob).then((snapshot) => {
                console.log('Uploaded a blob or file!');
            });

            let message_json = { 
                messaging_product: "whatsapp", 
                to: phoneNumber.substring(1), 
                type: "template", 
                template: { 
                    name: "laundry_buddy_invoice_pdf", 
                    language: { 
                        code: "en_US" 
                    },
                    components: [
                        {
                            type: "header",
                            parameters: [
                                {
                                    type: "document",
                                    document: {
                                        filename : "LaundryBuddy "+order_id,
                                        link: "https://firebasestorage.googleapis.com/v0/b/laundry-buddy-2938c.appspot.com/o/order_invoice%2F"+order_id+"?alt=media&token=d97111d7-d15b-4b4d-9c2f-a30aa01927c7"
                                    }
                                }
                            ]
                        }
                    ]
                } 
            }

            fetch("https://graph.facebook.com/v15.0/104530862478982/messages", {
                method: "post",
                headers: {
                    "Authorization": "Bearer EAAHcZBNnoVm4BAIV43RUJSbS9TVMSVn00NWco3uzACqboSWdWXdKSTcjqTvwpSy8FZCrpdYp4gE6Xuy5ZBTtKDU3KPgG5GiwYeDYRt3LJLmthhzieuVwCDgpdyyOhrZBJJZBoPqMnWbZCXFaGOjNKWWns49neLpPETgIZCQ7JxJoqtCzjKtWLfh2OPIPbuCqhOOqQSUkMYZBuwZDZD",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(message_json)
            }).then(res => res.json()).then(json => console.log(json)).catch(error => console.log(error))

            setTimeout(function(){
                const desertRef = ref(storage, 'order_invoice/'+order_id);
                deleteObject(desertRef).then(() => {
                    console.log("File deleted successfully");
                }).catch((error) => {
                    // Uh-oh, an error occurred!
                });
            }, 5000);
        }
        else {
            alert("Please add some items in the list before sending the bill. ");
        }
    }

    useEffect(() => {
        document.querySelectorAll(".left-select")[0].classList.add("active");
        document.querySelector(".setBillButton").setAttribute("disabled", "");
        document.querySelector(".setUserButton").setAttribute("disabled", "");
        document.querySelector(".discount-input").value = "0";
    }, [])


    return (
        <div className='billing-container'>
            <span className='user-exist'>This user is already existing. The details are given below.</span>
            <span className='bill-created'>The Bill has been created and sent to the Database.</span>
            <span className='user-exist-no'>This user is not there. Enter the details below.</span>
            <div className="user-info">
                <form onSubmit={getUserData} className="user-info-form">
                    <div className='info-div user-phone'>
                        <label for="phone">Phone Number : </label>
                        <input type="tel" name="phone" id='phone' className='phone' onChange={numberChange} minLength="10" maxLength="10" required />
                    </div>
                    <div className='info-div user-name'>
                        <label for="name">Name : </label>
                        <input type="text" name="name" id='name' className='name' />
                    </div>
                    <div className='info-div user-email'>
                        <label for="email">Email : </label>
                        <input type="email" name="email" id='email' className='email' />
                    </div>
                    <div className='info-div user-address'>
                        <label for="address">Address : </label>
                        <input type="text" name="address" id='address' className='address' />
                    </div>
                    <div className='info-button-div'>
                        <button className='info-div info-button searchButton'>SEARCH</button>
                        <button className='info-div info-button setUserButton' onClick={setUserData}>ADD USER</button>
                        <button className='info-div info-button setBillButton' onClick={setBillData}>SEND BILL</button>
                    </div>
                </form>
            </div>
            <div className='pricing-container'>
                <div className='pricing-heads'>
                    <div className='heads pricing-service'>
                        Service
                    </div>
                    <div className='heads pricing-category'>
                        Category
                    </div>
                    <div className='heads pricing-item'>
                        Item
                    </div>
                    <div className='heads pricing-quantity'>
                        Quantity & Price
                    </div>
                    <div className='heads pricing-pieces'>
                        Pieces
                    </div>
                    <div className='heads pricing-cost'>
                        Cost
                    </div>
                </div>
                <div className="pricing-content">
                    {billedItems.map((element, index) => (
                        <div key={index}>
                            <div className='heads pricing-service'>
                                {element.Service}
                            </div>
                            <div className='heads pricing-category'>
                                {element.Category}
                            </div>
                            <div className='heads pricing-item'>
                                {element.Item}
                            </div>
                            <div className='heads pricing-quantity'>
                                {element.Quantity} Qty. | Rs. {element.Price}
                                {element.Suffix}
                            </div>
                            <div className='heads pricing-pieces'>
                                {element.Pieces}
                            </div>
                            <div className='heads pricing-cost'>
                                Rs. {element.Cost}
                                <span className='remove-item' onClick={removeItem}>X</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='pricing-total-container'>
                    <div className='heads pricing-service'>
                    </div>
                    <div className='heads pricing-category'>
                    </div>
                    <div className='heads pricing-item'>
                    </div>
                    <div className='heads pricing-quantity'>
                        Total
                    </div>
                    <div className='heads pricing-total'>
                        Rs. {total}
                    </div>
                </div>
            </div>
            <form onSubmit={addItem} className="adding-order-container">
                <div className="adding-order-inputs">
                    <div className='heads order-service'>
                        <select id='service-select' onChange={serviceSelect} required>
                            <option value="">Select Service</option>
                            {displayService.map((element, index) => (
                                <option key={index} value={index}>{element}</option>
                            ))}
                        </select>
                    </div>
                    <div className='heads order-category'>
                        <select id='category-select' onChange={categorySelect} required disabled>
                            <option value="">Select Category</option>
                            {displayCategory.map((element, index) => (
                                <option key={index} value={index}>{element}</option>
                            ))}
                        </select>
                    </div>
                    <div className='heads order-item'>
                        <select id='item-select' onChange={itemSelect} required disabled>
                            <option value="">Select Item</option>
                            {displayItems.map((element, index) => (
                                <option key={index} value={index}>{element}</option>
                            ))}
                        </select>
                    </div>
                    <div className='heads order-quantity'>
                        Qty. <input type="text" name="" className='quantity-input' required disabled />
                        Rs. <input type="text" name="" className='price-input' required disabled />{suffix}
                    </div>
                    <div className='heads order-piece'>
                        Pcs <input type="text" name="" className='pieces-input' disabled />
                    </div>
                    <div className='heads order-discount'>
                        Discount <input type="number" name="" className='discount-input' required disabled />
                    </div>
                    <div className='heads order-dev'>
                        Delivery <input type="checkbox" name="" className='del-input' disabled />
                    </div>
                    <div className='heads order-exp-dev'>
                        Exp. Delivery <input type="checkbox" name="" className='exp-del-input' disabled />
                    </div>
                </div>
                <div className='heads order-button'>
                    <button type='submit'>Add Item</button>
                </div>
            </form>
        </div>
    )
}

export default NewOrder